
import React, { Component } from 'react';
import { connect } from 'react-redux';

import {login} from '../actions/auth';

import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';

import { getInstallations, getInstallationDetail } from '../actions/core';

import BasicChart from './basicChart';


import Moment from 'moment';

import { FaCamera } from 'react-icons/fa';
import { withTranslation } from 'react-i18next';

import { Trans } from 'react-i18next';
import Spinner from 'react-spinner-material';

import SelectorInstalaciones from './components/installations_select';


import
{
	BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, LineChart, Line

} from 'recharts';


//import axios from 'axios';
import { setup } from 'axios-cache-adapter';

import CONFIG from '../config/config';

import vCPU from '../config/vCPU';


import Select from 'react-select';

//import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import ToggleSwitch from './components/toggleSwitch';
import { getJSON } from 'redux-api-middleware';

//---

const $ = window.$;

const axios = setup(
						{
							cache:
								{
									maxAge: 500
								}
						}
					)

var mapLatlngbounds;


let tempThis = undefined;


var consulta_YA_realizada  =  false;

var CONT_axios_Alarms = 0;

//var CARGA_API_ALARMAS = false;

//var str_ALL_SLs_Alarms_Disabled = "";


var Installation_Group_ID =  "";
//var Installation_Group_NAME =  "";

//var aux_url_SELECT_INST_GROUP_OBJ = {};


var MAX_Nominal_Voltage =  parseFloat( 0.0 );


var our_installation_id =  "";

var our_Forecast_Avg_Sent =  -1.0;

var our_FC_coef =  0.0;


var ar_DATES_6_days_before_STR =  [];
var ar_CLOUDS_6_days_before =  [];


var CLOUDS_number_Forecast_on_Graphs = [];

//---


const CustomTooltip = 	( { active, payload, label } ) =>
						{

							let this_ = tempThis;

							if ( active )
							{
								let labeltext2 = payload[0].payload.time.split(" ")[1].split("+")[0];

								let labeltext = label + 1;

								if (  parseInt( label+1 )  <  10  )
								{
									labeltext = "0" + labeltext + ":00h";
								}
								else
								{
									labeltext = labeltext + ":00h";
								};

								labeltext = labeltext2;

								const valuetext = this_.props.t( "installation.cloudy" )  + " "  + payload[0].value  + "%";

								return (
											<div className="custom-tooltip" style={{opacity: 1, backgroundColor: "white", fontWeight: "bold", color: "black", border: "2px solid black", padding: "8px"}}>

												<p className="label">{`${labeltext} : ${valuetext}`}</p>

											</div>
								);

								//`
							} // if ( active )


							return null;

						};//


//-------------------


class Instalaciones extends Component
{

	constructor ( props ) // -----------------------------------------------------------------------
	{

		super( props );

		this.state =	{
							showTitles: false,
							installationgroups: [],

							selected_installationgroup:
														{
															group_name: "",

															id: "",

															info:
																{
																	streetlights: [],

																	available_sensors:[]
																}
														},

							selected_streetlight:
												{
													name: "",
													cpu: { version: 0 },
													battery: { cycle_count: 0 },

													hybrid: "",

													combined_sensors: []
												},


							RESULTS: [],  // AQUÍ VAN LOS RESULTADOS DE LOS DATOS OBTENIDOS PARA LAS GRÁFICAS


							showSpinner: false,
							forzarEncendido: false,
							forzadoTime: 0,


							preselected_installation_id: "",
							preselected_sl_id: "",

							str_ALL_SLs_Alarms_Disabled: ""
						};

		//------------------------------------------------------

		this.handleKeyDown = this.handleKeyDown.bind(this);
		this.handleKeyUp = this.handleKeyUp.bind(this);

		if ( props.location.search )
		{
			/*
			let installation_id = props.location.search.split("installation_id=");

			if (installation_id && props.location.search.indexOf("installation_id=")>-1)
			{
				installation_id = installation_id[1].split("&")[0];

				this.state.installations.map(
												(item, index) =>{
																	if ( item.id==installation_id )
																	{
																		this.state.selected_installation = item;
																		this.state.forzarEncendido = item.force_on;
																	}
																}
				);
			}


			let sl_id = props.location.search.split( "sl_id=" );

			if ( sl_id.length>0 && props.location.search.indexOf("sl_id=")>-1 )
			{
				sl_id = sl_id[1];

				this.state.selected_installation.streetlights.map(
																	(item, index) =>
																	{
																		if ( item.id == sl_id )
																		{
																			this.state.selected_streetlight = item;
																			this.setState( { selected_streetlight: item } );
																		}
																	}
				);
			}
			else
			{
				if ( this.state.selected_installation.streetlights.length > 0 )
				{
					this.state.selected_streetlight = this.state.selected_installation.streetlights[0];
				}
			}
			*/

		} // if




		this.need_update = false;

		this.prevSelectedSL = 0;

		this.selected_mapa =
								{
									latitud: "",

									longitud: ""
								};


		this.getSLData = this.getSLData.bind( this );

		this.resetBattery = this.resetBattery.bind( this );
		this.resetLuminary = this.resetLuminary.bind( this );
		this.forzarEncendido = this.forzarEncendido.bind( this );


	} // constructor ( props ) --------------------------------------------------------------------------------------------------------------------------------------------------------------


	handleKeyDown(e) {
		if (e.ctrlKey && e.key === 'q') {
		  this.setState({ showTitles: true }); // Mostrar títulos al presionar Ctrl + L
		  console.log("Hello")
		}
	  }

	// Método para manejar cuando se sueltan las teclas
	handleKeyUp(e) {
		if (e.ctrlKey && e.key === 'q') {
		  this.setState({ showTitles: false }); // Ocultar títulos al soltar Ctrl + L
		  console.log(`Bye, ${this.state.showTitles}`)
		}
	  }
	//----


	componentDidMount ( ) // -------------------------------------------------------------------------------------------------------------------------------------------------------------
	{

		// this.state.selected_installationgroup

		window.addEventListener('keydown', this.handleKeyDown);
    	window.addEventListener('keyup', this.handleKeyUp);

		const token = "Token "  +  this.props.auth.token;

		//--------

		if (  this.props.location.search.indexOf( "installation_id" ) > -1  )  //  >= 0
		{

			const aux_presel_inst_id =  this.props.location.search.split( "?installation_id=" )[1].split( "&" )[0];

			const aux_presel_sl_id 	 =	this.props.location.search.split( "&sl_id=" )[1];

			//---------



			this.state.preselected_installation_id =		aux_presel_inst_id;
			this.state.preselected_sl_id =					aux_presel_sl_id;

			//this.state.preselected_installation_id =	aux_presel_inst_id;
			//this.state.preselected_sl_id =				aux_presel_sl_id;


			this.state.selected_streetlight.id =  aux_presel_sl_id;

			/*
			//SERGIO - DESCARTADO
			this.state.selected_streetlight.id =  this.preselected_sl_id;  //new_st.id; //

			let new_st = { id: "" };
			new_st.id =  this.preselected_sl_id;

			this.state.selected_streetlight.id = new_st.id; //
			*/




			//---------------------------------------------------------------------------


			// LO PONEEMOS ABAJO DE ÉSTA FUNC (quitado ahora de abajo)

			this.setState(
							{
								preselected_installation_id:	aux_presel_inst_id,
								preselected_sl_id:				aux_presel_sl_id	//,

								//selected_streetlight: new_st  //SERGIO - DESCARTADO
							}
			);//




			/*
			selected_installationgroup:
			{
				group_name: "",

				id: "",

				info:
					{
						streetlights: [],

						available_sensors:[]
					}
			},
			*/


			//---------------------------------------------------------------------------



			//aux_url_SELECT_INST_GROUP_OBJ = {};

			Installation_Group_ID =  "";
			//Installation_Group_NAME =  "";


			// https://api.ilumek.com/api/v1/installationgroups


			const this_ =  this;


			axios.get(

						CONFIG.api_url  +   "/installationgroups",

						{
							"headers":
										{
											'Content-Type': 'application/json',
											'Authorization': token
										}
						}

			).then(
						( result ) =>
						{
							//console.log(  result.data  );



							//var id_FOUND_inst_group = "";
							//var our_inst_group_obj;


							//for (  let k = 0;  k  <   result.data.length;  k++  )
							//{


							//console.log(  result.data  );


							//Installation_Group_ID =  "";
							//Installation_Group_NAME =  "";


							( result.data ).forEach(
							//
								( element , index ) =>
								{
									//console.log( "____e: "   );
									//console.log(  e   );


									//var ITEMdat =  element;   //result.data[ k ];       //item;   //item;


									//console.log( ITEMdat.id );


									var Install_ID_found =  false;

									element.installations.forEach(

																	(  elem, ind  ) =>
																	{
																		if (  elem.id  ==  this_.preselected_installation_id  )
																		{
																			Install_ID_found =  true;

																			//window.alert(  elem.id  );


																			return Install_ID_found;
																		}// if
																	}

									);// .forEach()

									//-----


									if (  Install_ID_found  ==  true  )
									{

										Installation_Group_ID =  element.id;

										//Installation_Group_NAME =  element.group_name;



										CONFIG[ "setPreferredInstallationGroup" ]( Installation_Group_ID )






										this.state.selected_installationgroup.id =   Installation_Group_ID;









										/*
										this.setState(

											{
												preselected_installation_id:	aux_presel_inst_id,
												preselected_sl_id:				aux_presel_sl_id	//,

												//selected_streetlight: new_st  //SERGIO - DESCARTADO
											}

										);// this.setState(
										*/



										//this.getSLData(   aux_presel_sl_id   );



										//return;

										/*

										this_.state.selected_installationgroup.id =  		Installation_Group_ID;
										this_.state.selected_installationgroup.group_name =  Installation_Group_NAME;



										//let aux_obj =	{
										//					id:  			Installation_Group_ID,

										//					group_name:		Installation_Group_NAME,

										//					info:
										//					{
										//						streetlights: [],

										//						available_sensors:[]
										//					}
										/				};



										this_.state.selected_installationgroup = 	 aux_url_SELECT_INST_GROUP_OBJ;


										this_.setState(
														{	selected_installationgroup:		aux_url_SELECT_INST_GROUP_OBJ		}
										);//
										*/




										//window.alert(  element.id  );

										//return  Installation_Group_ID;

									} // if (  Install_ID_found  ==  true  )

								}// ( element , index ) =>
							//
							);// ( result.data ).forEach(


							//---------------------




							/*
							let aux_obj =	{
												id:  			Installation_Group_ID,

												group_name:		Installation_Group_NAME,

												info:
												{
													streetlights: [],

													available_sensors:[]
												}
											};
							*/

							/*
							this.setState(
											{
												selected_installationgroup:	{
																				id:  Installation_Group_ID,

																				group_name: "",

																				info:
																					{
																						streetlights: [],

																						available_sensors:[]
																					}
																			}

											}
							);//
							*/

							/*
							this.setState(
											{
												selected_installationgroup:  aux_obj
											}
							);//
							*/


						}// ( result ) =>

			).catch(

					error =>
							this.setState(
											{
												error,

												showSpinner: false
											}
							)//setState

			).finally(
			//
				( ) =>
				{
				}//
			//
			); // .finally  ---------------------------------





			//window.alert(  Installation_Group_ID  );


			//this.state.selected_installationgroup.id =  Installation_Group_ID;

				//group_name: "",

				//id: "",





			//SERGIO - DESCARTADO
			//this.getSLData( new_st );
		}
		else	// 		if (  this.props.location.search.indexOf( "installation_id" ) <= -1  )		//   < 0
		{

			if (
					(  this.props.location.search.indexOf( "sl_name" ) > -1  )  &&		// BUSQUEDAS SÓLO POR EL NOMBRE DE LA FAROLA (PETICIÓN DE ARNAITZ LUEGO USADA EN MÁS LUGARES)

					(  consulta_YA_realizada  ==  false )
			)
			{

				consulta_YA_realizada  =  true;


				var my_SL_name_URL =  this.props.location.search.split( "?sl_name=" )[1];

				//window.alert( my_SL_name_URL );

				var my_SL_id = "";
				var my_INSTA_id = "";

				var aux_BOOLe = false;

				//----

				//let token = "Token "  +  this.props.auth.token;





				const addurl = "/installationgroups";         // +  install_id  +  "/force";

				//----


				axios.get(

							CONFIG.api_url   +   addurl,         //{ "force": what },

							{
								"headers":
											{
												'Content-Type': 'application/json',
												'Authorization': token
											}
							}

				).then(

						( result ) =>
						{
							//console.log(  result.data  );



							var id_FOUND_inst_group = "";


							var our_inst_group_obj;



							//for (  let k = 0;  k  <   result.data.length;  k++  )
							//{

							( result.data ).every(
							//

								( element /*, index*/ ) =>
								{
									//console.log( "____e: "   );
									//console.log(  e   );


									//var ITEMdat =  element;   //result.data[ k ];       //item;   //item;


									//console.log( ITEMdat.id );


									if (  aux_BOOLe  ==  false  )
									{

										axios.get(
										//
													CONFIG.api_url  +   addurl  +   "/"  +  element.id,     //result.data[ k ].id,   //element.id,   //ITEMdat.id,

													{
														"headers":
																	{
																		'Content-Type': 'application/json',
																		'Authorization': token
																	}
													}

										).then(

												( r ) =>
												{

													our_inst_group_obj =  r.data;



												}// ( r ) =>

										).catch(

												error =>
														this.setState(
																		{
																			error,

																			showSpinner: false
																		}
														)  //setState

										).finally(

													() =>
													{
														//console.log( "_________" +  our_inst_group_obj.group_name + " .length= " + our_inst_group_obj.info.streetlights.length + "_________" );

														if ( our_inst_group_obj.info.streetlights.length  >  0  )
														{

															//console.log(  our_inst_group_obj.info.streetlights[0].name );

															//-------


															our_inst_group_obj.info.streetlights.every(
															//
																( elem /*, index*/ ) =>
																{
																	//console.log( elem.name );


																	if (  elem.name == my_SL_name_URL )
																	{

																		//console.log( "_____ENCONTRADO!______" );


																		id_FOUND_inst_group =   element.id;     //result.data[ k ].id;            //element.id;   //r.data.id;


																		my_SL_id =  elem.id;
																		my_INSTA_id = elem.installation_id;

																		//-----

																		//window.alert( "my_SL_id: " + my_SL_id + ";  my_INSTA_id: " + my_INSTA_id  );


																		this.preselected_installation_id    =   my_INSTA_id;
																		this.preselected_sl_id              =   my_SL_id;

																		this.setState(
																						{

																							preselected_installation_id:    this.preselected_installation_id,
																							preselected_sl_id:              this.preselected_sl_id //,

																							//selected_streetlight: new_st  //SERGIO - DESCARTADO
																						}
																		);


																		//this.props.location.search = "";

																		let new_url =   this.props.location.search.split( "?sl_name=" + my_SL_name_URL )[ 0 ] +

																							"?installation_id=" + my_INSTA_id +
																							"&sl_id="           + my_SL_id;


																		window.location.replace( new_url );

																		//return false;

																		//-----

																		//console.log(  elem  );

																		aux_BOOLe =  true;

																		//-----

																		return false;
																	}//


																	return ( elem.name != my_SL_name_URL );
																}//
															//
															); // .every()



															//console.log( "-----------" );




														}// if
													}

										); // finally

									} // if


									/*
									if (  aux_BOOLe  ==  true  )
									{
										break;
									}
									*/

									return  (  element.id  !=  id_FOUND_inst_group  );       //( aux_BOOLe == false );

								}// ( element /*, index*/ ) =>

							//
							);// .every



							//------------------------

							//for (  let k = 0;  ( k <  (result.data).length )  &&  (  aux_BOOLe == false );  k++  )


							//result.data.map(

											//( item, index ) =>
											//{

							//(result.data).every(

											//( item, index ) =>
											//( currentValue ) =>
											//{

												//console.log( element );







											//}//

							//);// .map()


						}// ( result ) =>

				).catch(

						error =>
								this.setState(
												{
													error,

													showSpinner: false
												}
								)//setState

				).finally(

					( ) =>
					{
						//window.alert( "ENTRO!");

						if (  aux_BOOLe  ==  true  )
						{



							//my_SL_id =  elem.id;
							//my_INSTA_id = elem.installation_id;


						}// if
					}

				); // .finally

				///--------




			}// if	( this.props.location.search.indexOf( "sl_name" ) > -1 )  &&   (  consulta_YA_realizada  ==  false )


			//------

			/*
			this.preselected_installation_id    =   my_INSTA_id;
			this.preselected_sl_id              =   my_SL_id;

			this.setState(
							{

								preselected_installation_id:    my_INSTA_id,
								preselected_sl_id:              my_SL_id //,

								//selected_streetlight: new_st  //SERGIO - DESCARTADO
							}
			);*/

		}// else

		//--------------------------------------------------------------------


		mapLatlngbounds = new this.props.google.maps.LatLngBounds();

		//this.props.getInstallations(this.props.auth.token, true);
		//this.getSLData(this.state.selected_installation.id, this.state.selected_streetlight);

		//$("#containerFluid").css("max-height", window.screen.availHeight);
		//$("html").css("overflow", "hidden");


		CONT_axios_Alarms = 0;


	} // componentDidMount ( ) ---------------------------------------------------------------------------------------------------------------------------------------------------------



	//--------------------------------------------------------------------------------------------------------------------------------------

	componentWillUnmount (  )
	{
		//$("html").css("overflow", "auto");
		window.removeEventListener('keydown', this.handleKeyDown);
		window.removeEventListener('keyup', this.handleKeyUp);
	} // componentWillUnmount (  )


	//--------------------------------------------------------------------------------------------------------------------------------------




	forzarEncendido ( what )
	{

		let install_id = this.state.selected_streetlight.installation_id;

		let url = "/installations/"  +  install_id  +  "/force";

		let token = "Token "  +  this.props.auth.token;

		const this_= this;


		axios.put(
					CONFIG.api_url + url, { "force": what },

					{
						"headers":	{
										'Content-Type': 'application/json',
										'Authorization': token
									}
					}
		).then(
		//
			( result ) =>
			{
				return;

				// Update info in global data

				//----
				/*
				let inst_index=0;

				this.props.core.installations.map(
													( item, index ) =>
																		{
																			if (  item.id  ==  this_.state.selected_installation.id  )
																			{
																				inst_index=index-1;
																			}
																		}
				);

				this_.state.installations[inst_index].force_on = what;
				this_.state.selected_installation = this.state.installations[inst_index];

				this_.props.getInstallationDetail(  this.props.auth.token, this.state.installations[inst_index].id  );
				*/
			}
		//
		).catch(
					error =>

						this.setState(
										{
											error,
										}
						)
		); // .catch(


	} // forzarEncendido ( what ) ----------------------------------------------------------------------------------------------------------------------------------------------------



	//--------------------------------------------------------------------------------------------------------------------------------------



	resetBattery ( sl_id, what ) // --------------------------------------------------------------------------------------------------------------------------------------------------
	{

		let url = "/streetlights/"  +  sl_id  +  "/reset_battery";


		let token = "Token "  +  this.props.auth.token;

		this.setState( { showSpinner: true } );

		axios.put(
					CONFIG.api_url  +  url,

					{},
					{
						"headers":
									{
										'Content-Type': 'application/json',
										'Authorization': token
									}
					}
		).then(
				( result ) =>
							{
								document.location.reload();
								this.setState( {showSpinner: false} );
							}
		).catch(
				error =>
							this.setState(
											{
												error,
												isLoading: false,
												showSpinner: false
											}
							)
		); // axios.put().then().catch()

	} // resetBattery ( sl_id, what ) ------------------------------------------------------------------------------------------------------------------------------------------------



	//--------------------------------------------------------------------------------------------------------------------------------------



	resetLuminary ( sl_id, what ) // --------------------------------------------------------------------------------------------------------------------------------------------------
	{

		let url = "/streetlights/"  +  sl_id  +  "/reset_luminary";

		let token = "Token " + this.props.auth.token;

		this.setState( {showSpinner: true} );

		axios.put(
					CONFIG.api_url  +  url,

					{},
					{
						"headers":
									{
										'Content-Type': 'application/json',
										'Authorization': token
									}
					}
		).then(
					( result ) =>
								{
									document.location.reload();
									this.setState( {showSpinner: false} );
								}
		).catch(
					error =>
								this.setState(
												{
													error,
													isLoading: false,
													showSpinner: false
												}
								)
		); // axios.put().then().catch()

	} // resetLuminary ( sl_id, what ) ---------------------------------------------------------------------------------------------------------------------------------------------



	//-----------------------------------------------------------------------------------------------------------------------------------------------------------------------------




	getSLData ( streetlight )
	{

		if (  streetlight === undefined  )
		{
			streetlight = this.state.selected_streetlight;
		}



		this.state.isLoading = true;
		this.state.showSpinner = true;


		this.setState(
						{
							selected_streetlight:
													{
														name: "",

														cpu: {version: 0},

														battery: {cycle_count: 0},

														hybrid: ""
													},

							showSpinner: true
						}
		);

		//this.state.isLoading = true;
		//this.state.showSpinner = true;


		//---


		//console.log(  this.state.selected_installationgroup  );


		axios.get(
					CONFIG.api_url  +  "/streetlights/"  +  streetlight.id,

					{
						"headers":
									{
										'Content-Type': 'application/json',
										'Authorization': "Token " + this.props.auth.token
									}
					}
		).then(

				( result ) =>
				{

					streetlight =  result.data;


					//------

					MAX_Nominal_Voltage =	parseInt(  streetlight.battery.nominal_voltage  )  *  2;

					//window.alert(  /*streetlight.battery.nominal_voltage*/ MAX_Nominal_Voltage   );

					//------

					//console.log(  streetlight  );



					//console.log( streetlight );

					our_installation_id = result.data.installation_id;

					//console.log( "getSLData().installation_id: "  +  installation_id  );


					this.state.selected_streetlight = result.data;

					this.setState(  { selected_streetlight: result.data }  );



					//console.log( "HYBRID: "  +  /*streetlight.hybrid*/this.state.selected_streetlight.hybrid  );





					//if (streetlight===undefined) {
					//  streetlight = this.state.selected_streetlight;
					//}

					let from = 1;
					let to =  Moment().valueOf()  /  1000;


					if (  $( "#option5dLabel" ).hasClass( "active" )  )
					{
						from =  Moment().subtract( {'days': 5} ).valueOf()  /  1000;
					}
					else
					{
						from =  Moment().subtract( {'hours': 24} ).valueOf()  /  1000;
					}

					from = from.toFixed(0);
					to = to.toFixed(0);



					//----

					/*
					console.log( "SERGIO _START_" );
					console.log( JSON.stringify( result.data.latitude )  );	// this.state.selected_installationgroup.info.streetlights
					console.log( JSON.stringify( result.data.longitude )  );	// this.state.selected_installationgroup.info.streetlights
					console.log( "SERGIO _END_" );
					*/
					/*
					this.mapa_aux.map.setCenter(
											{
												lat:result.data.latitude,
												lng:result.data.longitude
											}
					);
					*/

					let if_Hybrid_str =	(  streetlight.hybrid  ==  true  )	?	",RS"	:	"";

					//console.log( "Valor if_Hybrid_str:  "  +  if_Hybrid_str  );


					let url;

					if (  streetlight.cpu  )
					{

						if (  Math.abs( parseInt(streetlight.cpu.version) )  ==  vCPU[ "klm27v2" ]  )	// 2
						{
							// MGH BGN
							// url="/installations/"+installation_id+"/data.json?page_size=1000&vars=B,C,P,PC,D,BS&sl="+streetlight.id+"&start_date="+from+"&end_date=" + to;

							url =
									"/installations/"  +
															this.state.selected_installationgroup.id  +

									"/groupdata2.json"	+

									"?page_size=1000"	+

									"&vars=B,C,P,PC,D,BS,SO2,CO,NO2,O3,PMA,PMB,PMC"  +  if_Hybrid_str  +

									"&sl="  +
															streetlight.id  +

									"&start_date="  +
															from  +
									"&end_date=" +
															to;
						}
						else
						{
							if (  Math.abs( parseInt(streetlight.cpu.version) )  ==  vCPU[ "klm27v1" ]  )	// 1
							{
								url =
										"/installations/"  +
																this.state.selected_installationgroup.id  +

										"/groupdata2.json"  +

										"?page_size=1000"  +

										"&vars=B,P,C,D"	+ if_Hybrid_str + // MGH 2023-10


										"&sl="  +

																streetlight.id  +

										"&start_date="  +
																from  +
										"&end_date=" + to;
							}
							else // cpu = 3, vCPU[ "elorav1" ]
							{
								if (  Math.abs( parseInt(streetlight.cpu.version) )  ==  vCPU[ "elorav1" ]  )	// 3
								{
									//return;

									url =
											"/installations/"  +
																	this.state.selected_installationgroup.id  +

											"/groupdata2.json"  +

											"?page_size=1000"  +

											"&vars=LV,B,C,P,PC,D,BS,SO2,CO,NO2,O3,PMA,PMB,PMC"  +  if_Hybrid_str  +

											"&sl="  +
																	streetlight.id  +

											"&start_date="  +
																	from  +
											"&end_date=" +
																	to;
								}
							}
						}
					}//

					let token = "Token "  +  this.props.auth.token;




					//----------------------


					axios.get(
								CONFIG.api_url  +  url,

								{
									"headers":
												{
													'Content-Type': 'application/json',
													'Authorization': token
												}
								}
					).then(

							( result ) =>
							{

								result = result.data;


								//console.log( "--------------------------------------" );

								//console.log(  this.props.t( "installation.counting" )  );

								//console.log(  result );


								// asd = asd;

								const aux_OBJ =		Object.assign(
													//
																	{},

																	result
													//
													); // Object.assign(


								//console.log(  aux_OBJ.data  );

								//console.log( "--------------------------------------" );


								// Ok! Calculamos datos...

								if (  Math.abs( parseInt( streetlight.cpu.version ))  ==  vCPU[ "klm27v1" ]  )  //  1
								{

									let B =  streetlight.serial_centralita + "_B";
									let P =  streetlight.serial_centralita + "_P";

									let C =  streetlight.serial_centralita + "_C";

									let D =  streetlight.serial_centralita + "_D";	// [  this.props.t( "installation.counting" )  ]

									//let today = new Date();
									//let temp_epoch = new Date();


									// MGH 2023-10

									let RS = "";


									if (  streetlight.hybrid == true  )
									{
										RS = streetlight.serial_centralita + "_RS";
									}

									// MGH 2023-10 END

									result.data.map(
									//
										( item, index ) =>
										{
											if (  item[B] !== ""  )
											{
												aux_OBJ.data[index][  this.props.t( "installation.counting" )  ]		= item[D];	// [  this.props.t( "installation.counting" )  ]

												aux_OBJ.data[index][  this.props.t( "installation.battery_v" )  ]		= (  item[B]  ?  item[B]  :  0  ).toFixed(2);
												aux_OBJ.data[index][  this.props.t( "installation.panel_v" )  ]	 	= (  item[P]  ?  item[P]  :  0  ).toFixed(2);
												aux_OBJ.data[index][  this.props.t( "installation.consumption_a" )  ]  = (  item[C]  ?  item[C]  :  0  ).toFixed(2);


												// MGH 2023-10
												// SERGIO: PENDIENTE DE COMPROBACIÓN Y REVISIÓN CON MARCOS
												if (  streetlight.hybrid == true  )
												{

													//RS =  streetlight.serial_centralita + "_RS";
													aux_OBJ.data[index][ this.props.t( "installation.myRS" ) ] =				// 4º bit desde la derecha (indice de bit "3", -empezando de 0, claro-)
																												(
																													(

																														(
																															(

																																parseInt(  item[ RS ], 16 )
																															&
																																8								//  0000 0000 0000 0100

																															)  ==  8							//  0000 0000 0000 0100
																														)
																														&&
																														(
																																/*item[C]  >  0.0*/
																																true
																														)

																													)
																													?
																														25
																													:
																														0
																												);		// parseInt(    String( item[ RS ] )[1],  16  )
													//if (  item[RS] !== ""  ) console.log("Híbrida " + item[ RS ]);
													//if (  item[RS] == ""  ) console.log("NO DATA");

												} // if  (  streetlight.hybrid == true  )




											}// if (  item[B] !== ""  )

										}// ( item, index ) =>
									//
									);// result.data.map(


									if (  result.data.length > 0  &&  result.data[0] === undefined  )
									{
										result.data.shift();
									}

								}
								else 	// otras cpus
								{
									if (
										(  Math.abs( parseInt( streetlight.cpu.version ))  ==  vCPU[ "klm27v2" ]  )  ||
										(  Math.abs( parseInt( streetlight.cpu.version ))  ==  vCPU[ "elorav1" ]  )

									)	// cpu 2 y 3
									{
										let BS =  streetlight.serial_centralita + "_BS";

										let B =  streetlight.serial_centralita + "_B";
										let C =  streetlight.serial_centralita + "_C";
										let P =  streetlight.serial_centralita + "_P";

										let PC =  streetlight.serial_centralita + "_PC";

										let D =  streetlight.serial_centralita + "_D";		// [  this.props.t( "installation.counting" )  ]

										// MGH BGN
										let SO2 =  streetlight.serial_centralita + "_SO2";
										let CO =  streetlight.serial_centralita + "_CO";
										let NO2 =  streetlight.serial_centralita + "_NO2";
										let O3 =  streetlight.serial_centralita + "_O3";

										let PMA =  streetlight.serial_centralita + "_PMA";
										let PMB =  streetlight.serial_centralita + "_PMB";
										let PMC =  streetlight.serial_centralita + "_PMC";
										// MGH END


										let RS = "";

										if (  streetlight.hybrid == true  )
										{
											RS =  streetlight.serial_centralita + "_RS";
										}



										let LV = "";

										if (  Math.abs( parseInt( streetlight.cpu.version ))  ==  vCPU[ "elorav1" ]  )  // 3
										{
											LV =  streetlight.serial_centralita + "_LV";
										}//


										let final_result = [];


										// SERGIO314

										// TODO: CPU3: LV * C      ..... (en cpu=2 está puesto B * C)
										// MIRAR VAR "LV" (EN PRINCIPIO ESTÁ EN TRAMA AUNQUE NO ESTÉ EN VARS DE ARRIBA)


										result.data.map(
										//
											( item, index ) =>
											{
												if(  item[BS] !== ""  )
												{
													aux_OBJ.data[index][ this.props.t("installation.counting") ] = item[D];	// [  this.props.t( "installation.counting" )  ]


													if (  item[B] !== ""  )
													{
														//aux_OBJ.data[index][  this.props.t( "installation.counting" )  ]		= item[D];	// [  this.props.t( "installation.counting" )  ]

														aux_OBJ.data[index][  this.props.t( "installation.battery_v" )  ]		= (  item[B]  ?  item[B]  :  0  ).toFixed(2);
														aux_OBJ.data[index][  this.props.t( "installation.panel_v" )  ]	 	= (  item[P]  ?  item[P]  :  0  ).toFixed(2);
														aux_OBJ.data[index][  this.props.t( "installation.consumption_a" )  ]  = (  item[C]  ?  item[C]  :  0  ).toFixed(2);
													}


													//------


													if (  Math.abs( parseInt( streetlight.cpu.version ))  ==  vCPU[ "klm27v2" ]  )	// 2
													{
														aux_OBJ.data[index][ this.props.t("installation.consumption") ] = (  100*item[B]*item[C]  /  streetlight.total_power  ).toFixed(2);
													}//
													else // cpu 3, vCPU[ "elorav1" ]
													{
														if (  Math.abs( parseInt( streetlight.cpu.version ))  ==  vCPU[ "elorav1" ]  )	// 3
														{
															aux_OBJ.data[index][ this.props.t("installation.consumption") ] = (  100*item[ LV ]*item[C]  /  streetlight.total_power  ).toFixed(2);
														}
													}


													//------


													if (  aux_OBJ.data[index][ this.props.t("installation.consumption") ]  >= 100  )
													{
														aux_OBJ.data[index][ this.props.t("installation.consumption") ] = (  100  ).toFixed( 2 );
													}




													aux_OBJ.data[index][ this.props.t("installation.generation") ] =  (  100*item[P]*item[PC]  /  Math.max( 1, streetlight.panel.peak_power )  ).toFixed(2);

													if (  aux_OBJ.data[index][ this.props.t("installation.generation") ]  >=  100  )
													{
														aux_OBJ.data[index][ this.props.t("installation.generation") ] = (  100  ).toFixed( 2 );
													}



													aux_OBJ.data[index][ this.props.t("installation.charging") ] = ( item[BS] ).toFixed(2);

													if (  aux_OBJ.data[index][ this.props.t("installation.charging") ]  >=  100  )
													{
														aux_OBJ.data[index][ this.props.t("installation.charging") ] =  ( 100 ).toFixed( 2 );
													}




													// SERGIO: PENDIENTE DE COMPROBACIÓN Y REVISIÓN CON MARCOS
													if (  streetlight.hybrid == true  )
													{
														//RS =  streetlight.serial_centralita + "_RS";
														aux_OBJ.data[index][ this.props.t( "installation.myRS" ) ] =				// 4º bit desde la derecha (indice de bit "3", -empezando de 0, claro-)
																													(
																														(

																															(
																																(

																																	parseInt(  item[ RS ] , 16) 	// MGH Agregué ", 16"
																																&
																																	8								//  0000 0000 0000 1000

																																)  ==  8							//  0000 0000 0000 1000
																															)
																															&&
																															(
																																	/*item[C]  >  0.0*/
																																	true
																															)

																														)
																														?
																															100
																														:
																															0
																													);		// parseInt(    String( item[ RS ] )[1],  16  )

													} // if  (  streetlight.hybrid == true  )
																													// 16



													// MGH BGN
													if ( streetlight.has_air_sensor )
													{
														aux_OBJ.data[index][ "SO2" ] = ( item[SO2] ).toFixed(2);
														aux_OBJ.data[index][ "CO" ]  = ( item[CO] ).toFixed(2);
														aux_OBJ.data[index][ "NO2" ] = ( item[NO2] ).toFixed(2);
														aux_OBJ.data[index][ "O3" ]  = ( item[O3] ).toFixed(2);

														aux_OBJ.data[index][ "PM1.0" ] = ( item[PMA] ).toFixed(2);
														aux_OBJ.data[index][ "PM2.5" ] = ( item[PMB] ).toFixed(2);
														aux_OBJ.data[index][ "PM10" ] = ( item[PMC] ).toFixed(2);
													}
													// MGH END
												}
												else
												{
													aux_OBJ.data[index] = undefined;
												}

											}// ( item, index ) =>

										//
										);//.map()
									}
								}


								if (  result.data.length > 0  &&  result.data[0] === undefined  )
								{
									result.data.shift();
								}//


								const this_ = this;

								//--------

								setTimeout(
												( ) =>
												{
													this.setState(
																	{
																		showSpinner: false,


																		RESULTS:  	aux_OBJ,				// AQUI VAN LOS RESULTADOS


																		isLoading: false
																	}
													);
												},

												100
								);

							} // ( result ) => {
					).catch(
					//
						( error ) =>
						{
							this.setState(
											{
												error,

												RESULTS: [],

												isLoading: false,
												showSpinner: false
											}
							);
						}
					//
					); // axios.put().then().catch()

				} // ( result ) => { ..

		).catch(
					error =>
							this.setState(
											{
												error,
												showSpinner: false
											}
							)
		).finally(
		//
			( ) =>
			{

				//console.log(   "getSLData():  Installation.id: "  +  our_installation_id   );

				if (  this.state.selected_installationgroup.installations  !=  null  )
				{
					if (  this.state.selected_installationgroup.installations.length  >=  1  )
					{

						let ffound =  false;

						for (  let iii = 0;  ( iii  <  this.state.selected_installationgroup.installations.length  )  &&  ( ffound  ==  false );   iii++  )
						{

							if (  this.state.selected_installationgroup.installations[ iii ].id  ==  our_installation_id  /*this.state.selected_streetlight.installation_id*/  )
							{

								//console.log(  "(getSLData()) Dentro del EVERY: "  +  this.state.selected_installationgroup.installations[ iii ].id /*this.state.selected_streetlight.installation_id*/   );

								our_Forecast_Avg_Sent =  this.state.selected_installationgroup.installations[ iii ].forecast_Average_SL_sent;


								let aux_fc_avg =  our_Forecast_Avg_Sent  /  10.0;


								if (  aux_fc_avg  >=  9.0  )
								{
									our_FC_coef =  0.7;
								}
								else
								{
									if (  aux_fc_avg  >=  7.0  )
									{
										our_FC_coef =  0.8;
									}
									else
									{
										if (  aux_fc_avg  >=  5.0  )
										{
											our_FC_coef =  0.9;
										}
										else
										{
											our_FC_coef =  1.0;
										}
									}
								}



								//console.log(   our_Forecast_Avg_Sent   );

								ffound =  true;

							}// if (  elem.id  ==  this.

						}// for (  let iii = 0;  ( iii  <  his.state.selected_installationgroup.installations.length  )  &&  ( ffound  ==  false );   iii++  )


					}// if (  this.state.selected_installationgroup.installations.length  >=  1  )

				}// if (  this.state.selected_installationgroup.installations  !=  null  )


				//console.log( "<< Datos capturados de farola >>" );

			}//
		//
		);
		// axios.put().then().catch()


	} // getSLData ( streetlight ) ------------------------------------------------------------------------------------------------------------------------------------------------------



	//-----------------------------------------------------------------------------



	render (  )
	{

		tempThis =  this;


		let sunrise = "";
		let sunset =  "";

		let cloud_data = [];


		if ( this.state.selected_installationgroup  ==  "" )
		{
			cloud_data = [];
		}
		else
		{
			if (  this.state.selected_installationgroup.id  !=  ""  )
			{

				cloud_data =  this.state.selected_installationgroup.info[ "weather" ][ 'cloud_forecast_next_days' ];

			}// if (  this.state.selected_installationgroup.id  !=  ""  )

		}// else

		//----

		let weatherDays = {};  // 0: {"day": "", values: []},   1: {"day": "", values: []},   2: {"day": "", values: []}
		let DayIndex = -1;



		if (   this.state.selected_installationgroup.id.length > 3   &&   this.state.selected_installationgroup.info.weather.info   )
		{

			//console.log(  this.state.selected_installationgroup.info.weather.info  );


			// NO PARECE QUE SE USE MÁS QUE EN ÉSTE PUNTO...

			const aux_Day_Hour =  Object.keys(  this.state.selected_installationgroup.info.weather.info  ).sort();	// "this.state.selected_installationgroup.info.weather.info" muy grande de otros objetos (PERO NO ES UN ARRAY), simplemente cada campo que tiene es un objeto. Con ésta función sacamos el ARRAY que cada posición ŚOLAMENTE es la "key/nombre" de cada sub-objeto del original


			//console.log(  aux_Day_Hour  );

			aux_Day_Hour.map(
								( item, index ) =>
								{

									if (   weatherDays[ DayIndex ] === undefined   ||   (  item.split(" ")[0]  ==  weatherDays[ DayIndex ].day  ) == false   )
									{
										DayIndex++;

										weatherDays[ DayIndex ] =	{
																		"day": 		item.split(" ")[ 0 ],
																		values: 	[]
																	};
									}//


									weatherDays[ DayIndex ].values.push(
																			{
																				"clouds":	this.state.selected_installationgroup.info.weather.info[ item ].clouds,
																				"time":		item
																			}
									);

								} //

			); // aux_Day_Hour.map(


			sunrise = this.state.selected_installationgroup.info.weather.sun_info[ "sunrise_local" ].split( "T" )[1].split( "+" )[0];

			sunrise =	sunrise.split( ":" )[0]	+
						":"
						+
						sunrise.split( ":" )[1]	+	"h";


			sunset  = this.state.selected_installationgroup.info.weather.sun_info[ "sunset_local" ].split( "T" )[1].split( "+" )[0];

			sunset  =	sunset.split( ":" )[0]  +
						":" 	 				+
						sunset.split( ":" )[1] 	+	"h";

		} // if (  this.state.selected_installationgroup.id.length > 3   &&   this.state.selected_installationgroup.info.weather.info  )



		//-----------------------------------------
		//-----------------------------------------



		//------------------

		function getDaysInMonth ( year, month )
		{
			return new Date( year, month, 0 ).getDate();

		}// function getDaysInMonth ( year, month )

		//------------------


		//-----------------------------------------
		//-----------------------------------------


		//window.alert(  getDaysInMonth ( 2020, 2 )  );


		const OUR_DATE =  Moment().format( 'L' );


		const OUR_DATE_ar =  String(  OUR_DATE  ).split( '/' );


		const OUR_DAY =  parseInt(  OUR_DATE_ar[ 0 ]  );
		const OUR_MONTH =  parseInt(  OUR_DATE_ar[ 1 ]  );
		const OUR_YEAR =  parseInt(  OUR_DATE_ar[ 2 ]  );

		//console.log( Moment().format('L') );

		//console.log(  OUR_DAY  );
		//console.log(  OUR_MONTH  );
		//console.log(  OUR_YEAR  );


		//------------------

		ar_DATES_6_days_before_STR =  [];
		ar_CLOUDS_6_days_before =  [];







		//var our_JSON_OBJ_clouds =  null;
		const our_JSON_clouds =  this.state.selected_installationgroup.clouds_year/*_Historic*/;

		//console.log(  our_JSON_clouds  );


		var OBJ_our_JSON_clouds = {};


		if (
				( our_JSON_clouds  !=  undefined )  &&
				( our_JSON_clouds  !=  null )  &&
				( our_JSON_clouds  !=  "" )  &&
				( our_JSON_clouds.length  >  5 )


				//( typeof( our_JSON_clouds )  ===  'object' )  &&
				//( our_JSON_clouds  !=  {} )
			)
		{
			//console.log(  our_JSON_clouds[ 0 ]  );

			//const OBJ_our_JSON_clouds_year =  JSON.parse(  our_JSON_clouds  );

			const str_Year =  String(  OUR_YEAR  );
			//console.log(  str_Year  );




			try
			{
				OBJ_our_JSON_clouds =  JSON.parse(  our_JSON_clouds  );

				const OBJ_our_JSON_clouds_year__ACT_year =  OBJ_our_JSON_clouds[  str_Year  ];

				//console.log(  OBJ_our_JSON_clouds_year__ACT_year  );		//console.log(  OBJ_our_JSON_clouds_year__ACT_year[  "2022"  ]  );


				//------------------


				const NUMBER_BACK =  5;



				if (  OUR_DAY  <=  NUMBER_BACK  )
				{

					let dif_days =  NUMBER_BACK  -  OUR_DAY;

					if (  OUR_MONTH  ==  1  )
					{

						const OBJ_our_JSON_clouds_year__ANT_year =  JSON.parse(  our_JSON_clouds  )[  String(  OUR_YEAR - 1  )  ];	// puede dar "undefined" si no existe el año anterior

						//console.log(  OBJ_our_JSON_clouds_year__ANT_year  );



						for ( let idd =  31  -  dif_days  +  1;  idd  <=  31;  idd++  )
						{
							ar_DATES_6_days_before_STR.push(   String(  idd  +  "/"  +  "12"  +  "/"  +  ( OUR_YEAR - 1 )  ).split( '/' )   );

							ar_CLOUDS_6_days_before.push(   OBJ_our_JSON_clouds_year__ANT_year[  ( 12 - 1 )  ][  ( idd - 1 )  ]  );


						}// for ( let idd =  OUR_DAY - 4;  idd  <=  OUR_DAY;  idd++  )


						for ( let idd =  1;  idd  <=  OUR_DAY;  idd++  )
						{
							ar_DATES_6_days_before_STR.push(   String(  idd  +  "/"  +  OUR_MONTH  +  "/"  +  OUR_YEAR  ).split( '/' )   );

							ar_CLOUDS_6_days_before.push(   OBJ_our_JSON_clouds_year__ANT_year[  ( OUR_MONTH - 1 )  ][  ( idd - 1 )  ]  );

						}// for ( let idd =  OUR_DAY - 4;  idd  <=  OUR_DAY;  idd++  )

					}
					else
					{

						const PREV_MONTH_DAYS =  getDaysInMonth ( OUR_YEAR, OUR_MONTH - 1 );


						for ( let idd =  PREV_MONTH_DAYS  -  dif_days  +  1;  idd  <=  PREV_MONTH_DAYS;  idd++  )
						{
							ar_DATES_6_days_before_STR.push(   String(  idd  +  "/"  +  ( OUR_MONTH - 1 )  +  "/"  +  OUR_YEAR  ).split( '/' )   );

							ar_CLOUDS_6_days_before.push(   OBJ_our_JSON_clouds_year__ACT_year[  ( OUR_MONTH - 2 )  ][  ( idd - 1 )  ]  );

						}// for ( let idd =  OUR_DAY - 4;  idd  <=  OUR_DAY;  idd++  )


						for ( let idd =  1;  idd  <=  OUR_DAY;  idd++  )
						{
							ar_DATES_6_days_before_STR.push(   String(  idd  +  "/"  +  OUR_MONTH  +  "/"  +  OUR_YEAR  ).split( '/' )   );

							ar_CLOUDS_6_days_before.push(   OBJ_our_JSON_clouds_year__ACT_year[  ( OUR_MONTH - 1 )  ][  ( idd - 1 )  ]  );

						}// for ( let idd =  OUR_DAY - 4;  idd  <=  OUR_DAY;  idd++  )


					}// else
				}
				else// if (  OUR_DAY  >=  ( NUMBER_BACK + 1 )  )
				{
					for ( let idd =  OUR_DAY - NUMBER_BACK;  idd  <=  OUR_DAY;  idd++  )
					{
						ar_DATES_6_days_before_STR.push(   String(  idd  +  "/"  +  OUR_MONTH  +  "/"  +  OUR_YEAR  ).split( '/' )   );

						ar_CLOUDS_6_days_before.push(   OBJ_our_JSON_clouds_year__ACT_year[  ( OUR_MONTH - 1 )  ][  ( idd - 1 )  ]  );

					}// for ( let idd =  OUR_DAY - 4;  idd  <=  OUR_DAY;  idd++  )

				}// else


				//----------------------


				//console.log( "------------------------------------------" );

				//console.log(  ar_DATES_6_days_before_STR  );

				//console.log(  ar_CLOUDS_6_days_before  );


				if (   this.state.selected_streetlight  !=  null   )
				{
					//-----

					var ar_TS__DATES_6_days_before =  [];


					for (  let idayz =  0;  idayz  <  ar_DATES_6_days_before_STR.length;  idayz++  )	// <  ar_CLOUDS_6_days_before.length;
					{

						const STR_auxx =	String(
													//String( 12 ).padStart( 2, '0' )

													String(  ar_DATES_6_days_before_STR[  idayz  ][  2  ]  )  +  "-"  +

													String(  ar_DATES_6_days_before_STR[  idayz  ][  1  ]  ).padStart( 2, '0' )  +  "-"  +

													String(  ar_DATES_6_days_before_STR[  idayz  ][  0  ]  ).padStart( 2, '0' )  +  "T"  +  "00:00:00"

											); // String

						//console.log(  STR_auxx  );

						const TS_auxx =	parseInt(	(   new Date(  STR_auxx  )   ).getTime()	/	1000	);

						// "2022-09-19T15:40:00 . 000Z"

						ar_TS__DATES_6_days_before.push(	TS_auxx		);

					}// for (  let idayz =  0;  idayz  <  ar_CLOUDS_6_days_before.length;  idayz++  )


					//console.log(   ar_TS__DATES_6_days_before   );




					//const NOWTIME = new Date(  "2022-09-19T15:40:00"  );
					//console.log(  NOWTIME.getTime()  );

					//console.log(   String( 12 ).padStart( 2, '0' )   );


					//console.log(  new Date(  "2022-9-8T15:40:00"  )  );


					//NOWTIME:		1 663 682 156 	351

					//epoch: 		1 663 594 980 	000
					//timestamp:	"2022-09-19T15:40:00 . 000Z"

					//				1 663 594 800	 000


					//console.log(  "selected_streetlight, es distinto de null"  );
					//console.log(  this.state.selected_streetlight  );
					//console.log(  this.state.RESULTS  );


					//----------


					//ar_DATES_6_days_before_STR
					//ar_CLOUDS_6_days_before


					( this.state.RESULTS ).data.map(
					//
						( item, index ) =>
						{

							const STR_TS_item =  item[ "timestamp" ].split(  '.'  )[ 0 ];

							const TS_item =  (  new Date(  STR_TS_item  )  ).getTime()   /   1000;


							//console.log(  STR_TS_item  );

							//console.log(  TS_item  );



							if (
									(  TS_item  >=    ar_TS__DATES_6_days_before[  0  ]  )  &&
									(  TS_item  <  (  ar_TS__DATES_6_days_before[  0  ]  +  ( 24 * 60 * 60 )  )  )
							)
							{
								item[ "FC" ] =	(  ar_CLOUDS_6_days_before[  0  ][ "Clouds" ]  ).toFixed(2);			// [  this.props.t( "installation.counting" )  ]
							}
							else
							{
								if (
										(  TS_item  >=    ar_TS__DATES_6_days_before[  1  ]  )  &&
										(  TS_item  <  (  ar_TS__DATES_6_days_before[  1  ]  +  ( 24 * 60 * 60 )  )  )
								)
								{
									item[ "FC" ] =	(  ar_CLOUDS_6_days_before[  1  ][ "Clouds" ]  ).toFixed(2);
								}
								else
								{
									if (
											(  TS_item  >=    ar_TS__DATES_6_days_before[  2  ]  )  &&
											(  TS_item  <  (  ar_TS__DATES_6_days_before[  2  ]  +  ( 24 * 60 * 60 )  )  )
									)
									{
										item[ "FC" ] =	(  ar_CLOUDS_6_days_before[  2  ][ "Clouds" ]  ).toFixed(2);
									}
									else
									{
										if (
												(  TS_item  >=    ar_TS__DATES_6_days_before[  3  ]  )  &&
												(  TS_item  <  (  ar_TS__DATES_6_days_before[  3  ]  +  ( 24 * 60 * 60 )  )  )
										)
										{
											item[ "FC" ] =	(  ar_CLOUDS_6_days_before[  3  ][ "Clouds" ]  ).toFixed(2);
										}
										else
										{
											if (
													(  TS_item  >=    ar_TS__DATES_6_days_before[  4  ]  )  &&
													(  TS_item  <  (  ar_TS__DATES_6_days_before[  4  ]  +  ( 24 * 60 * 60 )  )  )
											)
											{
												item[ "FC" ] =	(  ar_CLOUDS_6_days_before[  4  ][ "Clouds" ]  ).toFixed(2);
											}
											else
											{
												if (
														(  TS_item  >=    ar_TS__DATES_6_days_before[  5  ]  )  &&
														(  TS_item  <  (  ar_TS__DATES_6_days_before[  5  ]  +  ( 24 * 60 * 60 )  )  )
												)
												{
													item[ "FC" ] =	(  ar_CLOUDS_6_days_before[  5  ][ "Clouds" ]  ).toFixed(2);
												}
												else
												{
													item[ "FC" ] =	0.0;
												}// else

											}// else

										}// else

									}// else

								}// else

							}// else



							//----


						}// ( item, index ) =>
					//
					);// result.data.map(




					//console.log(  this.state.RESULTS  );



				}// if (   this.state.selected_streetlight  !=  null   )


				//console.log( "------------------------------------------" );



				//console.log(  OBJ_our_JSON_clouds_year__ACT_year  );


				//console.log(  OBJ_our_JSON_clouds_year__ACT_year[  OUR_MONTH  -  1  ]  );


				/*

				if (  $( "#option5dLabel" ).hasClass( "active" )  )
				{
					console.log(  "5 dias"  );

					//from =  Moment().subtract( {'days': 5} ).valueOf()  /  1000;
				}
				else
				{
					console.log(  "24 horas"  );

					//from =  Moment().subtract( {'hours': 24} ).valueOf()  /  1000;
				}

				//from = from.toFixed(0);
				//to = to.toFixed(0);

				*/


			}
			catch ( error )
			{
				console.error( error )
			}//


			//-----------------------------

		}// if (   our_JSON_clouds  !=  null   )


		//-----------------------------





		//CLOUDS_number_Forecast_on_Graphs = [];

		CLOUDS_number_Forecast_on_Graphs =	( ) =>
		{

			var ar_our_HTML = [];


			if (  ( ar_DATES_6_days_before_STR  !=  null )  &&  ( ar_CLOUDS_6_days_before  !=  null )  )
			{

				if (  ( ar_DATES_6_days_before_STR.length  >  0 )  &&  ( ar_CLOUDS_6_days_before.length  >  0 )  )
				{

					if (  $( "#option5dLabel" ).hasClass( "active" )  )
					{
						//console.log(  "5 dias"  );

						ar_our_HTML.push(
							(
								<td style={{paddingLeft: "125px", paddingRight: "0px"}}> <p> </p> </td>
							)
						);

						//----

						for (  let iiid = 0;   iiid  <  ar_CLOUDS_6_days_before.length;   iiid++   )
						{
							ar_our_HTML.push(
								(
									<td style={{paddingRight: "200px"}}> <p>{ String(  ar_DATES_6_days_before_STR[ iiid ][ 0 ]  +  "("  +  Math.round( ar_CLOUDS_6_days_before[ iiid ][ "Clouds" ] ) +  ")")}</p> </td>
								)
							);

						}// for (  let iiid = 0;   iiid  <  ar_CLOUDS_6_days_before.length;   iiid++   )
					}
					else
					{
						//console.log(  "24 horas"  );

						ar_our_HTML.push(
							(
								<td style={{paddingLeft: "250px", paddingRight: "250px"}}> <p> </p> </td>
							)
						);

						//----

						for (  let iiid = ar_CLOUDS_6_days_before.length - 2;   iiid  <  ar_CLOUDS_6_days_before.length;   iiid++   )
						{
							ar_our_HTML.push(
								(
									<td style={{paddingRight: "500px"}}> <p>{ String(  ar_DATES_6_days_before_STR[ iiid ][ 0 ]  +  "("  +  Math.round(ar_CLOUDS_6_days_before[ iiid ][ "Clouds" ])  +  ")")}</p> </td>
								)
							);

						}// for (  let iiid = 0;   iiid  <  ar_CLOUDS_6_days_before.length;   iiid++   )

						//from =  Moment().subtract( {'hours': 24} ).valueOf()  /  1000;

					}// else

				}//

			}//	if (  ( ar_DATES_6_days_before_STR  !=  null )  &&  ( ar_CLOUDS_6_days_before  !=  null )  )

			//-------

			return  ar_our_HTML;

		};// ( ) =>




		//------------------------------------------------------------------
		//-----------------------------------------------------------------------------------------------------------------------------------------


		if (
			this.state.selected_streetlight.name !== ""  				&&

			this.state.selected_installationgroup.id !== "" 			&&
			this.state.selected_installationgroup.group_name   !== ""	&&

			CONT_axios_Alarms  <=  0
		)
		{


			//window.alert( String(  this.state.selected_installationgroup.group_name  )  );		//window.alert( String(  this.state.preselected_installation_id  )  );

			//---------

			// https://api.ilumek.com/api/v1/alarms?page=1&filtro={"type":"filter","value":{"sortField":null,"filters":{"streetlight.installation_group":{"filterVal":"Sensórica","filterType":"SELECT","comparator":"=","caseSensitive":false}, "disabled_alarms":{"filterVal":true,"filterType":"SELECT","comparator":"=","caseSensitive":false}},"page":0,"sizePerPage":50}}&fromDate=1587748699&toDate=1653377439


			//this.props.auth.user.user_profile.userlevel  ==  "EKIONA" &&

			let str_FILTER_VAL =	(  this.props.auth.user.user_profile.userlevel  ==  "EKIONA"  )
									?
										"true"
									:
										"false";

			//--------

			axios.get(
						CONFIG.api_url	+	"/alarms"	+
														"?page=1"	+
														"&filtro="	+

														'{"type":"filter","value":{"sortField":null,"filters":'	+

															'{'		+

																'"streetlight.installation_group":{"filterVal":'	+

																													'"' + this.state.selected_installationgroup.group_name + '"'		+		//'"Sensórica"'		+

																									',"filterType":"SELECT","comparator":"=","caseSensitive":false}, '		+

																'"disabled_alarms":{"filterVal":'	+

																								str_FILTER_VAL	+	',"filterType":"SELECT","comparator":"=","caseSensitive":false}},"page":0,"sizePerPage":100}}',		//'&fromDate=1587748699&toDate=1653377439',

																							//  "filterVal":false,  -->  AHORA: "filterVal":true,

						{
							"headers":
										{
											'Content-Type': 'application/json',
											'Authorization': "Token " + this.props.auth.token
										}
						}
			).then(
			//
					( result ) =>
					{


						let AUX_str_ALL_SLs_Alarms_Disabled =  JSON.stringify(  result.data.info.all_streetlights  );


						//window.alert(  AUX_str_ALL_SLs_Alarms_Disabled  );


						AUX_str_ALL_SLs_Alarms_Disabled =  AUX_str_ALL_SLs_Alarms_Disabled.toLowerCase();



						//window.alert(  AUX_str_ALL_SLs_Alarms_Disabled  );



						//console.log(  "HA LLEGADOF AQUÍ, ALARMAS"  );
						//console.log(  AUX_str_ALL_SLs_Alarms_Disabled  );
						//console.log(  result.data  );



						this.state.str_ALL_SLs_Alarms_Disabled =  AUX_str_ALL_SLs_Alarms_Disabled;

						this.setState(	{
											str_ALL_SLs_Alarms_Disabled: 	AUX_str_ALL_SLs_Alarms_Disabled,
										}
						);//

					}
			//
			);// ... .then()




			//---------


			CONT_axios_Alarms++;

		} // if ( .... &&  ....  &&  .... )



		//--------------------------------------------------------------------------


		if (
				!this.state.isLoading  							&&

				this.state.selected_streetlight.name !== ""  	&&

				this.state.selected_installationgroup.id !== "" &&

				this.prevSelectedSL < 3
			)
		{

			this.state.selected_installationgroup.info.streetlights.map(
																			( item ) =>
																			{
																				mapLatlngbounds.extend(   new this.props.google.maps.LatLng(  item.latitude,item.longitude  )   );
																			}
			); // ... .map()

			this.prevSelectedSL++;

		} // if (  ...  &&  ...  &&  ...  &&  ....  )


		// ---



		if (  this.state.selected_installationgroup.info.streetlights.length == 1  )
		{
			setTimeout(
			//
				( ) =>
				{
					if (   this.map === undefined  ||  this.map === null  ||  this.prevSelectedSL > 0   )
					{
						return;
					}//


					//---

					var zoom =  this.map.map.getZoom();

					this.map.map.fitBounds( mapLatlngbounds );


					if (  this.state.selected_streetlight.name  !==  ""  )
					{

						mapLatlngbounds.extend(
													new this.props.google.maps.LatLng(
																						this.state.selected_installationgroup.info.streetlights[ 0 ].latitude,
																						this.state.selected_installationgroup.info.streetlights[ 0 ].longitude
													)//
						);//

						this.selected_mapa.latitud =  this.state.selected_installationgroup.info.streetlights[ 0 ].latitude;
						this.selected_mapa.longitud = this.state.selected_installationgroup.info.streetlights[ 0 ].longitude;

						this.map.map.setCenter(
												{
													lat: this.selected_mapa.latitud,
													lng: this.selected_mapa.longitud
												}
						);//

						//this.getSLData( this.state.selected_streetlight );
						/*
						this.map.map.setCenter(
												{
													lat:this.state.selected_installationgroup.info.streetlights[0].latitude,
													lng:this.state.selected_installationgroup.info.streetlights[0].longitude
												}
						);
						*/

					} // if

					//this.map.map.setZoom(zoom > 15 ? 15 : zoom);
					//this.map.map.fitBounds( mapLatlngbounds );	// SERGIO: PUESTO ARRIBA
				}

				, 500

			//
			) // setTimeout(
		}
		else // if (  this.state.selected_installationgroup.info.streetlights.length != 1  )
		{

			setTimeout(
							( ) =>
							{
								if (  this.map   &&   this.prevSelectedSL < 3  )
								{
									var zoom =  this.map.map.getZoom();

									this.map.map.fitBounds( mapLatlngbounds );

									//this.map.map.setZoom(zoom > 20 ? 20 : zoom);

									//---

									// AÑADIDO SERGIO 23-11-21 (HASTA FIN IF)

									if (  this.state.selected_installationgroup.info.streetlights.length > 0  )
									{
										//let indx_presel_SL = this.state.selected_installationgroup.info.streetlights.indexOf( this.preselected_sl_id );
										// console.log( /*indx_presel_SL*/ JSON.stringify( this.state.selected_installationgroup.info.streetlights ) /*.length*/ );

										const my_id = this.preselected_sl_id;

										let indx_presel_SL =
																this.state.selected_installationgroup.info.streetlights.findIndex(
																																	( e ) =>
																																	{
																																		return e.id == my_id //this.preselected_sl_id;
																																	}
																);//

										//---

										if ( indx_presel_SL >= 0 )
										{
											//this.setState(  { selected_streetlight: this.state.selected_installationgroup.info.streetlights[ indx_presel_SL ] }  );

											this.selected_mapa.latitud = this.state.selected_installationgroup.info.streetlights[ indx_presel_SL ].latitude;
											this.selected_mapa.longitud = this.state.selected_installationgroup.info.streetlights[ indx_presel_SL ].longitude;

											this.map.map.setCenter(
																	{
																		lat: this.selected_mapa.latitud,
																		lng: this.selected_mapa.longitud
																	}
											);

											//this.getSLData( this.state.selected_streetlight );

										}// if ( indx_presel_SL >= 0 )

									} // if (  this.state.selected_installationgroup.info.streetlights.length > 0  )

								}// if (  this.map  &&  this.prevSelectedSL < 3  )
							}

							, 500

			) // setTimeout(

		}// else

		//-----------------



		//this.state.selected_installationgroup.info.streetlights.map(
																		//( item, index ) =>
																		//{

																			//console.log( item.name );

																			//const const_item_SL =  item;




																			/*

																			var ar_objs =  document.querySelectorAll( "div" );

																			//console.log( ar_objs );

																			for ( let els = 0;  els < ar_objs.length; els++ )
																			{

																				if (  ar_objs[ els ].title  ==  item.name )
																				{


																					console.log( ar_objs[ els ].title );


																					let aux_Marker_id = "id_Marker_" + item.name;

																					ar_objs[ els ].id =  aux_Marker_id;


																					console.log( ar_objs[ els ] );


																				}// if


																			}//

																			*/

																																					/*
																					ar_objs[ els ].addEventListener(
																														"mousedown",

																														( event ) =>
																														{
																															window.alert( "dffsdsfsdf" );
																														},

																														false
																					);//
																					*/



																			/*
																			let aux_Marker_id = "id_Marker_" + item.name;

																			//console.log( aux_Marker_id );



																			//------------

																			if (  document.getElementById( aux_Marker_id )  !=  null  )
																			{

																				console.log( aux_Marker_id );

																				document.getElementById( aux_Marker_id ).addEventListener(
																																			'mousedown',

																																			() =>
																																			{
																																				window.alert( "dffsdsfsdf" );
																																			}
																				);//

																			}// if
																			*/

																			//if (  /*item_.id == item.id*/ false  )
																			//{
																				//this_.getSLData( item_ );



																				/*
																				this.map.map.setCenter(
																										{
																											lat:item_.latitude,
																											lng:item_.longitude
																										}
																				);

																				this_.getSLData( item_ );
																				*/
																				//----

																				//var zoom =  this.map.map.getZoom();

																					//this.map.map.fitBounds( mapLatlngbounds );
																					//this.map.map.setZoom(zoom > 20 ? 20 : zoom);

																				//---

																				// AÑADIDO SERGIO 23-11-21 (HASTA FIN IF)
																				/*
																				if (  this.state.selected_installationgroup.info.streetlights.length > 0  )
																				{
																					let indx_presel_SL =
																										this.state.selected_installationgroup.info.streetlights.findIndex(
																																											( e ) =>
																																											{
																																												return e.id == this.preselected_sl_id;
																																											}
																										);

																					//---

																					if ( indx_presel_SL >= 0 )
																					{
																						this.map.map.setCenter(
																												{
																													lat:this.state.selected_installationgroup.info.streetlights[ indx_presel_SL ].latitude,
																													lng:this.state.selected_installationgroup.info.streetlights[ indx_presel_SL ].longitude
																												}
																						);
																					}
																				} // if
																				*/
																				//----

																			//}// if

																		//}//
		//); // .map()


		//------------------------------------------------------------------



		/*
		MAX_Nominal_Voltage =  0;

		if (   (   this.state.selected_installationgroup   !=   undefined   )   &&  (   this.state.selected_installationgroup   !=   null   )     )
		{

			if (   (   this.state.selected_installationgroup.installations   !=   undefined   )   &&   (   this.state.selected_installationgroup.installations   !=   null   )   &&   (   this.state.selected_installationgroup.installations.length   >=   1   )   )
			{

				MAX_Nominal_Voltage =	parseInt(  this.state.selected_installationgroup.installations[0].streetlights[0].battery.nominal_voltage  )  *  2;

				window.alert(  this.state.selected_installationgroup.installations[0].streetlights[0].battery.nominal_voltage   );
			}//

		}//


		window.alert(   MAX_Nominal_Voltage   );
		*/


		//------------------



		return (

					<div className="container-fluid" style={{overflow: "auto"}} id="containerFluid">

						{

						//console.log( this.props.auth.user ) &&

						this.state.isLoading &&


						<div style={{position: "absolute", left: "48%", top: "45%", zIndex: 100000}}>
							<Spinner radius={120} spinnerColor={"#333"} spinnerWidth={2} visible={true} />
						</div>
						}


						<div id="cab-general" className="container-fluid" style={{marginLeft: "-12px"}}>

							<div className="actions-left col-md-4 col-xs-12" style={{marginTop: "-5px"}}>

								<li className="nav-item dropdown" >



									<SelectorInstalaciones

										showAll = { false }

										preselectedInstallation = { this.state.preselected_installation_id }

										onDataLoaded =	{
															( e ) =>
															{
																mapLatlngbounds = new this.props.google.maps.LatLngBounds();

																//this.prevSelectedSL = 0;

																CONT_axios_Alarms = 0;
															}
														}

										onDataChanged =
														{
															( e ) =>
															{
																mapLatlngbounds = new this.props.google.maps.LatLngBounds();

																this.prevSelectedSL = 0;


																if (  e.latitude < 1e-6  )
																{
																	e.latitude  = e.info.streetlights[0].latitude;
																	e.longitude = e.info.streetlights[0].longitude;
																}


																this.state.selected_installationgroup = e;

																this.setState(  { selected_installationgroup: e }  );



																if(  this.state.selected_installationgroup.info.streetlights.length > 0  )
																{
																	//this.state.selected_streetlight= this.state.selected_installationgroup.info.streetlights[0];

																	if(  this.state.preselected_sl_id  )
																	{

																		for(  let i = 0;  i < this.state.selected_installationgroup.info.streetlights.length;  i++  )
																		{
																			if (  this.state.selected_installationgroup.info.streetlights[i].id  ==  this.state.preselected_sl_id  )
																			{
																				this.getSLData(  this.state.selected_installationgroup.info.streetlights[i]  );

																				break;
																			}
																		}// for
																	}
																	else
																	{
																		this.getSLData(  this.state.selected_installationgroup.info.streetlights[0]  );

																	}
																} // if

																this.state.preselected_installation_id = undefined;
																this.state.preselected_sl_id = undefined;

																CONT_axios_Alarms = 0;

															} // (e) ) =>
														}
									/>


								</li>

							</div>




							<div className="actions-right">
							{
								(  (  this.state.selected_streetlight.urlwebcam  )   &&   (  this.state.selected_streetlight.urlwebcam.indexOf( "http" )  >  -1  )   )  &&

								<a href="#a" className="btn btn-default btn-sm">

									<img
											src="img/camera.png"

											style={{width: "35px", marginTop: "-4px"}}

											alt = "camera_img"

											onClick =
													{
														( e ) =>
														{
															$( "#webcamIframe" ).attr(  "src", this.state.selected_streetlight.urlwebcam  );
															$( "#webcamInfo").modal( "show" );
														}
													}
									/>

								</a>

							}

							{
								(
									(
										(  Math.abs( parseInt(this.state.selected_streetlight.cpu.version) )  ==  vCPU[ "klm27v2" ]  )		// 2
										||
										(  Math.abs( parseInt(this.state.selected_streetlight.cpu.version) )  ==  vCPU[ "elorav1" ]  )		// 3
									)

									&&  this.state.selected_streetlight.cpu_is_master  )  &&


								<ToggleSwitch

									checked = { this.state.selected_streetlight.installation_forced_on }

									onlabel = { this.props.t( "installation.unforce_on" ) }
									onstyle = 'danger'
									offlabel = { this.props.t( "installation.force_on" ) }
									offstyle = 'success'
									style = 'w-50 mx-3'

									onChange =
												{
													( checked ) =>
													{
														const this_ = this;

														let sl = this.state.selected_streetlight;

														sl.installation_forced_on = checked;

														this.setState(  { selected_streetlight: sl }  );
														//this.setState({forzarEncendido: checked, forzadoTime: (new Date()).getTime()});

														this.forzarEncendido( checked );

														setTimeout(
																	( ) =>
																	{
																		this_.getSLData( this_.state.selected_streetlight );
																	}

																	,2500
														);

													} // ( checked ) =>
												}
								/>
							}
							</div>

						</div>



						<div className="row">

							<nav className="col-md-4 col-lg-3 col-xl-2 d-md-block sidebar" style={{opacity: this.state.selected_streetlight.name==""?"15%":"100%"}}>

								<div className="sidebarInfo" style={{overflow: "auto"}}>


									{ /* start: SERGIO CODE 22-11-21 :  SELECTOR DE FAROLAS DENTRO DEL INSTALLATION GROUP */ }

									<div className="actions-left col-md-4 col-xs-12" style={{marginLeft: "-0.7em"}}>


										<h3 style={{marginTop: "0.4em", padding: "0.1em", display: "flex", flexDirection: "row"}}>

											{ /*this.props.t( "installation.streetlight" )*/ }  { /*this.state.selected_streetlight.name*/ }
											{/*&#62;*/}

											<select

												id = "id_selectSLs"

												className="nav-item dropdown"

												style =
														{
															{
																padding: "0.5em",
																paddingRight: "0.0em",
																marginRight: "0.5em",

																backgroundColor: "white",
																fontWeight: "normal",

																borderColor: "lightgrey",
																borderStyle: "groove",
																borderWidth: "thin",
																borderRadius: "3px",
															}
														}

												onChange =
															{
																( event ) =>
																{
																	let new_st = { id: "" };

																	new_st.id =  event.target.value;

																	this.state.selected_streetlight.id = new_st.id; //


																	///this.getSLData( new_st );		// PUESTO TRAS EL IF


																	//--

																	let my_id = event.target.value;

																	let indx_presel_SL =
																							this.state.selected_installationgroup.info.streetlights.findIndex(
																																								( e ) =>
																																								{
																																									return e.id == my_id //new_st.id //this.state.selected_streetlight.id  //this.preselected_sl_id;
																																								}
																							);

																	//---

																	if ( indx_presel_SL >= 0 )
																	{
																		this.selected_mapa.latitud = this.state.selected_installationgroup.info.streetlights[ indx_presel_SL ].latitude;
																		this.selected_mapa.longitud = this.state.selected_installationgroup.info.streetlights[ indx_presel_SL ].longitude;
																		/*
																		this.map.map.setCenter(
																								{
																									lat: this.selected_mapa.latitud,
																									lng: this.selected_mapa.longitud
																								}
																		);
																		*/


																		//this.setState(  { selected_streetlight: this.state.selected_installationgroup.info.streetlights[ indx_presel_SL ] }  );
																		//this.state.selected_streetlight.id = new_st.id;
																	}
																	/*
																	this.map.map.setCenter(
																							{
																								lat:this.state.selected_streetlight.latitude,
																								lng:this.state.selected_streetlight.longitude
																							}
																	);
																	*/
																	//$( "#id_selectSLs" ).css( 'borderWidth', 'medium' );
																	//$( "#id_selectSLs" ).css( 'borderColor', '#4FAFFF' );


																	this.getSLData( new_st );

																	/*
																	this.map.map.setCenter(
																							{
																								lat: this.selected_mapa.latitud,
																								lng: this.selected_mapa.longitud
																							}
																	);*/

																}
															}

												onClick =
															{
																( ) =>
																{
																	//console.log( "CLICK CLOCK  " );
																	$( "#id_selectSLs" ).css( 'borderWidth', 'medium' );
																	$( "#id_selectSLs" ).css( 'borderColor', '#4FAFFF' );

																	document.getElementById( "id_selectSLs" ).addEventListener(
																																"focusout",

																																( ) =>
																																{
																																	$( "#id_selectSLs" ).css( 'borderWidth', 'thin' );
																																	$( "#id_selectSLs" ).css( 'borderColor', 'lightgrey' );
																																}
																	);// .addEventListener(...)

																}
															}
											>
											{
												this.state.selected_installationgroup.info.streetlights.map(
																												st =>

																														<option

																															value = { st.id }
																															selected =  { this.state.selected_streetlight.id == st.id }

																															style =
																																	{
																																		{
																																			backgroundColor: "white",
																																			fontWeight: ( this.state.selected_streetlight.id == st.id ) ? "bold" : "normal",
																																			color: ( this.state.selected_streetlight.id == st.id ) ? "black" : "grey",
																																			//padding: "0.5em",
																																			//margin: "0.5em"
																																		}
																																	}
																															/*
																															onClick =
																																	{
																																		( e ) =>
																																		{
																																			//this.getSLData( e );
																																			this.setState( { selected_streetlight: e } );
																																		}
																																	}*/
																														>
																															{ this.props.t( "installation.streetlight" ) } { " "  +  st.name  +  " " }

																														</option>
												)//
											}
											</select>



											{
											//url: `${ CONFIG.server_url }/${ item.marker }`,

											this.props.auth.user.user_profile.userlevel == "EKIONA" &&

											<img
													src={/*"https://api.ilumek.com/static/markers/greendothole32.png"*/ "https://www.google.com/images/branding/product/ico/maps15_bnuw3a_32dp.ico"}

													alt="map_img"

													onClick=	{
																	( ) =>
																	{


																		var our_lat =  this.state.selected_streetlight.latitude; //this.state.selected_streetlight.latitude;
																		var our_lon =  this.state.selected_streetlight.longitude; //this.state.selected_streetlight.longitude;


																		var our_url =  "https://www.google.es/maps/place/"		// 43%C2%B019'11.8%22N+2%C2%B000'33.4%22W";

																		our_url +=  our_lat  +  ","  +  our_lon;

																		//--------

																		/*
																		var calc_degree = parseInt( Math.floor( our_lat ) );

																		our_url +=  calc_degree + "%C2%B0";


																		var aux_calc_mins =  ( our_lat - calc_degree )  *  60.0;
																		var calc_mins = parseInt( Math.floor( aux_calc_mins ) );

																		our_url +=  calc_mins + "'";


																		var aux_calc_secs = parseFloat(  ( aux_calc_mins - calc_mins )  *  60.0  ).toFixed( 1 );
																		//var calc_secs = parseInt( Math.round( aux_calc_secs ) );

																		our_url +=  aux_calc_secs + "%22N+";

																		//-------


																		var calc_degree = parseInt( Math.floor( our_lon ) );

																		our_url +=  calc_degree + "%C2%B0";


																		var aux_calc_mins =  ( our_lon - calc_degree )  *  60.0;
																		var calc_mins = parseInt( Math.floor( aux_calc_mins ) );

																		our_url +=  calc_mins + "'";


																		var aux_calc_secs = parseFloat(  ( aux_calc_mins - calc_mins )  *  60.0 ).toFixed( 1 );
																		//var calc_secs = parseInt( Math.round( aux_calc_secs ) );

																		our_url +=  aux_calc_secs + "%22W";



																		window.alert( 	our_lat + "\n" +
																						our_lon + "\n\n" +

																						our_url );
																		*/


																		var our_win = window.open( our_url, '_blank' );
																		our_win.focus();
																	}
																}

											/>
											}

										</h3>

									</div>
									{ /* end: SERGIO CODE 22-11-21 :  SELECTOR DE FAROLAS DENTRO DEL INSTALLATION GROUP */ }



									<ul id="listado-detalles">

										<li>
											<div className="dato">
												<p> { this.props.t( "installation.model" )  } </p>
											</div>

											<div className="resultado">
												<p> { this.state.selected_streetlight.model } </p>
											</div>
										</li>

										<li>
											<div className="dato">
												<p> { this.props.t( "installation.serial" ) } </p>
											</div>

											<div className="resultado">
												<p> { this.state.selected_streetlight.serial_streetlight } </p>
											</div>
										</li>

										<li>
											<div className="dato">
												<p> { this.props.t( "installation.hierarchy" ) } </p>
											</div>

											<div className="resultado">
												<p> { this.state.selected_streetlight.cpu_is_master ? 'Master' : 'Slave' } </p>
											</div>
										</li>

										<li>
											<div className="dato">
												<p> { this.props.t( "installation.solar_panel" ) } (Wp)</p>
											</div>

											<div className="resultado">
												<p> { this.state.selected_streetlight.panel  &&  this.state.selected_streetlight.panel.peak_power } </p>
											</div>
										</li>


										{ /* (  this.props.auth.user.user_profile.userlevel  ==  "EKIONA"  )  */ }



										<li style={ {border: 0} }>

											<div className="dato">
												{/* &#62; */ }

												<p>
													<b> { ( this.props.auth.user.user_profile.userlevel == "EKIONA" ) && this.props.t( "installation.battery" ) } </b>	 { ( ( this.props.auth.user.user_profile.userlevel != "EKIONA" ) && this.state.selected_streetlight.battery ) && ( this.props.t( "installation.battery" )  +  " "  +  this.props.t( "installation.capacity_c_20" ) )  } (Ah)
												</p>

											</div>

											{
											( this.props.auth.user.user_profile.userlevel != "EKIONA" ) &&

											<div className="resultado">
												<p> { this.state.selected_streetlight.battery  && this.state.selected_streetlight.battery.capacity_ah } </p>
											</div>
											}

										</li>


										{
										this.props.auth.user.user_profile.userlevel == "EKIONA" &&

										<li style={{border: 0}}>
											<div className="dato">
												<p> { this.props.t( "installation.capacity_c_20" ) } (Ah) </p>
											</div>

											<div className="resultado">
												<p> { this.state.selected_streetlight.battery && this.state.selected_streetlight.battery.capacity_ah } </p>
											</div>

										</li>
										}


										{
										this.props.auth.user.user_profile.userlevel == "EKIONA" &&

										<li>
											<div className="dato">
												<p> { this.props.t( "installation.battery_cycles" )  }</p>

												<p>
													<button
														className="btn btn-sm"

														onClick=
																{
																	( e ) =>
																	{
																		this.resetBattery(  this.state.selected_streetlight.id,  "battery_cycles"  );
																	}
																}
													> Reset
													</button>
												</p>
											</div>

											<div className="resultado">
												<p>{this.state.selected_streetlight.battery.cycle_count}</p>
											</div>
										</li>
										}


										{ /* ------------------------------------------------------------------------ */ }


										{

										this.props.auth.user.user_profile.userlevel != "EKIONA" &&

										<hr style={{marginBottom: 0, marginTop: 0}}/>

										}



										<li>


											<li style={{border: 0}}>
											{
												this.props.auth.user.user_profile.userlevel  ==  "EKIONA" &&

												<div className="dato">
													{/* &#62; */ }
													<p> <b> { this.props.t( "installation.luminary" ) } </b> </p>
												</div>
											}

											{
												/*
												<div className="resultado">
												</div>
												*/
											}
											</li>


											<li style={{border: 0}}>
											{
												this.props.auth.user.user_profile.userlevel  ==  "EKIONA" &&

												<div className="dato">
													<p> { this.props.t( "installation.lum_eficiency" ) } </p>
												</div>
											}

											{
												this.props.auth.user.user_profile.userlevel  ==  "EKIONA" &&

												<div className="resultado">
													<p> { Math.round( this.state.selected_installationgroup.info.mean_efficiency ) } </p>
												</div>
											}
											</li>


											<li style={{border: 0}}>

											{
												this.props.auth.user.user_profile.userlevel  !=  "EKIONA" &&

												<div className="dato">
													{/* &#62; */ }
													<p> { this.props.t( "installation.luminary" ) +  " "  +  this.props.t( "installation.consumption" )  +  " (W)"  /*+  Math.round( this.state.selected_streetlight.total_power )*/  } </p>
												</div>
											}

											{
												this.props.auth.user.user_profile.userlevel  ==  "EKIONA" &&

												<div className="dato">
													<p>  { this.props.t( "installation.consumption" ) } (W) </p>
												</div>
											}

											{
												<div className="resultado">
													<p> { Math.round( this.state.selected_streetlight.total_power ) } </p>
												</div>
											}
											</li>




											<li style={{border: 0}}>
												{
												this.props.auth.user.user_profile.userlevel  ==  "EKIONA" &&

												<div className="dato">

													<p> { this.props.t( "installation.working_time" ) } (h) </p>

												</div>
												}

												{
												this.props.auth.user.user_profile.userlevel  ==  "EKIONA" &&

												<div className="resultado">
													<p> { this.state.selected_streetlight.total_night_time  &&  this.state.selected_streetlight.total_night_time.toFixed(0) }</p>
												</div>
												}
											</li>



											{ /* ------------------------------------------------------------------------ */ }


											<li style={{border: 0}}>
												{
												this.props.auth.user.user_profile.userlevel  ==  "EKIONA" &&

												(  this.state.selected_streetlight.hybrid == true  ) &&

												<div className="dato">

													<p> Tiempo de func. Batería { /*this.props.t( "installation.working_time" ) */} (h) </p>
												</div>
												}

												{
												this.props.auth.user.user_profile.userlevel  ==  "EKIONA" &&

												(  this.state.selected_streetlight.hybrid == true  ) &&

												<div className="resultado">
													<p> { this.state.selected_streetlight.total_night_time  &&  this.state.selected_streetlight.total_RS_OFF.toFixed(0) }</p>
												</div>
												}
											</li>






											<li style={{border: 0}}>
												{
												this.props.auth.user.user_profile.userlevel  ==  "EKIONA" &&

												(  this.state.selected_streetlight.hybrid == true  ) &&

												<div className="dato">

													<p> Tiempo de func. Red { /*this.props.t( "installation.working_time" ) */} (h) </p>
												</div>
												}

												{
												this.props.auth.user.user_profile.userlevel  ==  "EKIONA" &&

												(  this.state.selected_streetlight.hybrid == true  ) &&

												<div className="resultado">
													<p> { this.state.selected_streetlight.total_night_time  &&  this.state.selected_streetlight.total_RS_ON.toFixed(0) }</p>
												</div>
												}
											</li>








											<li style={{border: 0}}>
												{
												this.props.auth.user.user_profile.userlevel  ==  "EKIONA" &&

												<div className="dato">

													<p>
														<button
															className="btn btn-sm"

															onClick=
																	{
																		( e ) =>
																		{
																			this.resetLuminary(  this.state.selected_streetlight.id,  "luminary_hours"  );
																		}
																	}
														> Reset
														</button>
													</p>

												</div>
												}
											</li>


										</li>


										<li>

											<div className="dato">
												{/* &#62; */ }
												<p> <b> { this.props.t( "installation.accesories" ) } </b> </p>
											</div>

											<div className="resultado">
											{
												[
													"has_air_sensor",
													"has_noise_sensor",
													"has_polen_sensor",
													"has_presence",
													"has_sos_button",
													"has_surveillance",
													"has_traffic_sensor",
													"has_uv_sensor",

													"hybrid"

												].map(
														( item, index ) =>
														{
															if (  this.state.selected_streetlight  &&  this.state.selected_streetlight[item]  )
															{
																return (  CONFIG.getIconImg( item, this.props.t )  )
															}
														}
												) // [...].map(...)
											}

											{

												(  this.state.selected_streetlight && this.state.selected_streetlight.combined_sensors  )  &&
												(  this.state.selected_streetlight.combined_sensors.length  <  1  )  &&

											<div>
												<Trans>main.no_sensors</Trans>
											</div>

											}

											</div>

										</li>




										{
											this.state.selected_installationgroup.id != "all"  &&

										<li>

											<div className="dato" style={{width: "100%"}}>
												<p> { this.props.t( "installation.cloud_forecast" ) } </p>

												<table style={{width: "100%", marginTop: 10}}>

													<tr>
													{
														cloud_data !== undefined  &&

														cloud_data.slice( 0, 4 ).map(
																						( item, index ) =>
																						{
																							if (  item.value > 65  )
																							{

																								return 	<td>
																											<img
																												src = "img/very_cloudy.png"
																												title = {item.dom}
																												className = "col-md-3"
																												style = {{maxWidth: "60px", cursor: "pointer"}}

																												onClick =
																															{
																																( e ) =>
																																{
																																	$( "#weatherInfo" ).modal( "show" );
																																}
																															}
																											/>
																										</td>
																							}
																							else
																							{
																								if (  item.value > 35  )
																								{
																									return 	<td>
																												<img
																													src = "img/cloudy.png"
																													title = { item.dom }
																													className = "col-md-3"

																													style = { { maxWidth: "60px", cursor: "pointer" } }

																													onClick =
																																{
																																	( e ) =>
																																	{
																																		$("#weatherInfo").modal("show");
																																	}
																																}
																												/>
																											</td>
																								}
																								else
																								{
																									return 	<td>
																												<img
																													src = "img/sunny.png"
																													title = { item.dom }
																													className = "col-md-3"
																													style = { { maxWidth: "60px", cursor: "pointer" } }

																													onClick =
																																{
																																	( e ) =>
																																	{
																																		$( "#weatherInfo" ).modal( "show" );
																																	}
																																}
																												/>
																											</td>
																								}
																							}

																							return item.value;
																						}
														) //  ... .map()
													}
													</tr>
												</table>
											</div>

										</li>
										}

									</ul>



									<h3> { this.props.t("installation.schedule") } </h3>


									<ul className="listado-comun">

										<li>
											<div className="resultado" style={{marginTop: "10px", width: "100%", position: "relative"}}>
												<p>
													{
													this.state.selected_streetlight.current_schedule  &&
													<span>{ this.state.selected_streetlight.current_schedule.scheduleprofile.serial_streetlight }</span>
													}
												</p>

												<div className="chart_100" style={{position: "absolute"}}>
													100%
												</div>

												<div className="chart_0" style={{position: "absolute", marginTop: "70px"}}>
													0%
												</div>

												<div style={{display: "inline-block", height: "120px", width: "95%", marginLeft: "15px"}}>
													{
													this.state.selected_streetlight.current_schedule  &&
													<img
														src=
															{
																CONFIG.api_url  +  "/../.."  +  this.state.selected_streetlight.current_schedule.scheduleprofile.thumbnail
															}
														style=
															{
																{ width: "100%", marginLeft: "-10%", height: "100px" }
															}
													/>
													}
												</div>

												<p style={{width: "95%", marginLeft: "5%", marginTop: "-20px", marginBottom: "40px"}}>

													<div style={ {float: "left", textAlign: "center", marginLeft: "5%"} }>
														<img src="img/sunset.png" style={{height: "30px"}}/>
														<br/>
														{sunset}
													</div>

													<div style={{float: "right", textAlign: "right", marginRight: "12%"}}>
														<img src="img/sunrise.png" style={ {height: "30px"} }/>
														<br/>
														{sunrise}
													</div>

												</p>

											</div>
										</li>
									</ul>


								</div>

							</nav>



							<main id="info-torres" role="main" className="col-md-8 col-lg-9 col-xl-10 ml-sm-auto">

								<div style={{width: "100%", height: "300px", paddingLeft: "5px"}}>
								{
									this.state.selected_installationgroup.latitude  &&

									// MAPA con los markers de SLs

									<Map
										google = { this.props.google }
										zoom = { 13 }
										bounds = { mapLatlngbounds }
										mapTypeControl = { true }
										style = { { width: "100%", height: "300px" } }

											ref =
													{
														( e ) =>
														{
															this.map = e;

															if ( e )
															{
																e.map.setMapTypeId( 'satellite' );
																e.map.setTilt( 0 );

																this.mapa_aux = e;
															}

														}//
													}

											initialCenter=
															{
																{
																	lat: `${ this.selected_mapa.latitud }`,				//`${this.state.selected_installationgroup.latitude}`,
																	lng: `${ this.selected_mapa.longitud }`				//`${this.state.selected_installationgroup.longitude}`
																}
															}
									>

									{
										// MARKER DEL MAPA

										this.state.selected_installationgroup.info.streetlights.map(
										//
											( item ) =>
											{

												const this_ = this;


												// The selected one...

												if (  item.id == this.state.selected_streetlight.id  )
												{


													//window.alert( this.state.str_ALL_SLs_Alarms_Disabled );


													//if (   (  this.state.str_ALL_SLs_Alarms_Disabled).indexOf(  (item.serial_streetlight).toLowerCase()  )  >=  0   )
													//{
													//	window.alert( "ESTÁ EN EL SELECCIONADO" );
													//}// if


													/*
													let icon =
																{
																	url: (  (this.state.str_ALL_SLs_Alarms_Disabled).indexOf(  (item.serial_streetlight).toLowerCase()  )  <=  -1   )	?	"https://api.ilumek.com/static/markers/greendothole32.png"	:	`${ CONFIG.server_url }/${ item.marker }`,

																	scaledSize: new this.props.google.maps.Size( 45, 45 )
																};
													*/


													let	icon =	{
																	url:
																			(  this.props.auth.user.user_profile.userlevel  ==  "EKIONA"  )
																			?
																				`${ CONFIG.server_url }/${ item.marker }`
																			:
																				(
																					(    (  this.state.str_ALL_SLs_Alarms_Disabled  ).indexOf(   ( item.serial_streetlight ).toLowerCase()   )  <=  -1    )
																					?
																						(
																							"https://api.ilumek.com/static/markers/green"
																																			+	(
																																					(  item.cpu_is_master  ==  true  )
																																					?
																																						"dot"
																																					:
																																						""
																																				)

																																			+	"hole32.png"
																						)
																					:
																						`${ CONFIG.server_url }/${ item.marker }`
																					)
																			,


																			/*
																			{
																				(   (  this.state.str_ALL_SLs_Alarms_Disabled  ).indexOf(   ( row.serial_streetlight ).toLowerCase()   )  <=  -1    )
																				?

																					(  this.props.auth.user.user_profile.userlevel  ==  "EKIONA"  )		?	"https://api.ilumek.com/static/markers/greendothole32.png"	:	`${CONFIG.server_url}/${row.marker}`
																				:
																					(  this.props.auth.user.user_profile.userlevel  ==  "EKIONA"  )		?	`${CONFIG.server_url}/${row.marker}`	:	"https://api.ilumek.com/static/markers/greendothole32.png"			//`${CONFIG.server_url}/${row.marker}`
																			}


																			(  ( this.state.str_ALL_SLs_Alarms_Disabled ).indexOf(  ( item.serial_streetlight ).toLowerCase()  )  <=  -1   )	// FAROLA NO EXISTE EN STR_LISTA_FAROLAS
																			?
																				"https://api.ilumek.com/static/markers/green"
																																+	(
																																		(  item.cpu_is_master  ==  true  )
																																		?
																																			"dot"
																																		:
																																			""
																																	)

																																+	"hole32.png"
																			:
																				`${ CONFIG.server_url }/${ item.marker }`,
																			*/


																	scaledSize:
																				new this.props.google.maps.Size( 45, 45 )
																}



													/*
													this.map.map.setCenter(
																			{
																				lat: this.selected_mapa.latitud,
																				lng: this.selected_mapa.longitud
																			}
													);*/

													//console.log( item );

												return  this.state.showTitles === true ? (
													<InfoWindow
														visible={this.state.showTitles}
														position={{ lat: `${item.latitude}`, lng: `${item.longitude}` }}
														onCloseClick={() => this.setState({ showTitles: false })}
														maxHeight={50}
													>
														<div  style={{ width: 'max-content', height: 'max-content' }}>
															<p>{item.name}</p>
														</div>
													</InfoWindow>
												) : (

																<Marker

																	title = {item.name}

																	name = {item.name}
																	position = {{lat: `${item.latitude}`, lng: `${item.longitude}`}}

																	icon = {icon}

																	onClick =
																				{
																					( e ) =>
																					{
																						//const e_ = e;

																						//console.log( ( window.event ) );

																						//window.alert( e.id );

																						this_.setState(
																										{ showSpinner: true }

																						); // this_.setState(

																						//----

																						this_.state.selected_installationgroup.info.streetlights.map(
																						//

																							( item_, index ) =>
																							{

																								if (  item_.id == item.id  )
																								{
																									this_.getSLData( item_ );

																									/*
																									this.map.map.setCenter(
																															{
																																lat:item_.latitude,
																																lng:item_.longitude
																															}
																									);

																									this_.getSLData( item_ );
																									*/
																									//----

																									//var zoom =  this.map.map.getZoom();

																										//this.map.map.fitBounds( mapLatlngbounds );
																										//this.map.map.setZoom(zoom > 20 ? 20 : zoom);

																									//---

																									// AÑADIDO SERGIO 23-11-21 (HASTA FIN IF)
																									/*
																									if (  this.state.selected_installationgroup.info.streetlights.length > 0  )
																									{
																										let indx_presel_SL =
																															this.state.selected_installationgroup.info.streetlights.findIndex(
																																																( e ) =>
																																																{
																																																	return e.id == this.preselected_sl_id;
																																																}
																															);

																										//---

																										if ( indx_presel_SL >= 0 )
																										{
																											this.map.map.setCenter(
																																	{
																																		lat:this.state.selected_installationgroup.info.streetlights[ indx_presel_SL ].latitude,
																																		lng:this.state.selected_installationgroup.info.streetlights[ indx_presel_SL ].longitude
																																	}
																											);
																										}
																									} // if
																									*/
																									//----

																								}//

																							} // ( item_, index ) =>

																						//
																						) // ... .map(

																					} // ( e ) => {
																				}

																/>

															)

												} // if (  item.id == this.state.selected_streetlight.id  )


												//----

												// Not the selected one...

												return  this.state.showTitles === true ? (
													<InfoWindow
														visible={this.state.showTitles}
														position={{ lat: `${item.latitude}`, lng: `${item.longitude}` }}
														onCloseClick={() => this.setState({ showTitles: false })}
														maxHeight={50}
													>
														<div  style={{ width: 'max-content', height: 'max-content' }}>
															<p>{item.name}</p>
														</div>
													</InfoWindow>
												) : (
															//<div
															//	id = { "id_Marker_" + item.name }
															//>

																<Marker

																	title = {item.name}
																	name = {item.name}

																	position = { {lat: `${item.latitude}`, lng: `${item.longitude}`} }

																	icon =	{
																				(  this.props.auth.user.user_profile.userlevel  ==  "EKIONA"  )
																				?
																					`${ CONFIG.server_url }/${ item.marker }`
																				:
																					(
																						(    (  this.state.str_ALL_SLs_Alarms_Disabled  ).indexOf(   ( item.serial_streetlight ).toLowerCase()   )  <=  -1    )

																						?
																							(
																								"https://api.ilumek.com/static/markers/green"
																																				+	(
																																						(  item.cpu_is_master  ==  true  )
																																						?
																																							"dot"
																																						:
																																							""
																																					)

																																				+	"hole32.png"
																							)
																						:
																							`${ CONFIG.server_url }/${ item.marker }`
																					)


																				/*
																				(  ( this.state.str_ALL_SLs_Alarms_Disabled ).indexOf(  ( item.serial_streetlight ).toLowerCase()  )  <=  -1   )
																				?
																					"https://api.ilumek.com/static/markers/green"
																																	+	(
																																			(  item.cpu_is_master  ==  true  )
																																			?
																																				"dot"
																																			:
																																				""
																																		)

																																	+	"hole32.png"
																				:
																					`${ CONFIG.server_url }/${ item.marker }`
																				*/
																			}


																	onClick =
																				{
																					( e ) =>
																					{
																						/*
																						e.target.addEventListener(
																											'mousedown',

																											() => { window.alert( "hola" ); }
																						);*/

																						//console.log( window.event );


																						this_.setState( { showSpinner: true } );

																						this_.state.selected_installationgroup.info.streetlights.map(
																						//
																							( item_, index ) =>
																							{
																								if (  item_.id == item.id  )
																								{
																									this_.getSLData( item_ );
																									/*
																									this.map.map.setCenter(
																															{
																																lat:item_.latitude,
																																lng:item_.longitude
																															}
																									);

																									this_.getSLData( item_ );*/

																								} // if  (  item_.id == item.id  )

																							} // ( item_, index ) =>

																						//
																						) // .map(
																					}
																				}
																/>

														) // return(...)

											} // ( item ) =>
										//
										) // ... .map( ... )
									}
									</Map>
								}
								</div>



								{
								this.props.auth.user.user_profile.userlevel == "EKIONA" &&

								<center> <br/> <h3 style={{marginTop: -1, marginBottom: -40}}> { this.state.selected_installationgroup.group_name } </h3> </center>

								}


								<div className="container cont-graph" style={{maxWidth: "2000px", marginBottom: "200px"}}>


									<div className="chartjs-size-monitor" style={{position: "absolute", left: "0px", top: "0px", right: "0px", bottom: "0px", overflow: "hidden", pointerEvents: "none", visibility: "hidden", zIndex: "-1"}}>

										<div className="chartjs-size-monitor-expand" style={{position:"absolute", left:"0", top:"0", right:"0", bottom:"0", overflow:"hidden", pointerEvents:"none", visibility:"hidden", zIndex:"-1"}}>
											<div style={{position:"absolute", width:"1000000px", height:"1000000px", left:"0", top:"0"}}>
											</div>
										</div>

										<div className="chartjs-size-monitor-shrink" style={{position:"absolute", left:"0", top:"0", right:"0", bottom:"0", overflow:"hidden", pointerEvents:"none", visibility:"hidden", zIndex:"-1"}}>
											<div style={{position:"absolute", width:"200%", height:"200%", left:"0", top:"0"}}>
											</div>
										</div>

									</div>



									<div className="row graph-actions-inline">


										<div className="col-md-6 graph-title">
											<h2> { this.props.t( "installation.charts" ) } </h2>
										</div>


										<div className="col-md-6 text-right graph-actions">
											<div className="btn-group btn-group-toggle active" id="timerange" data-toggle="buttons">


												<label
													className="btn btn-sm btn-light active"
													id ="option24hLabel"

													onClick =
															{
																( e ) =>
																{
																	setTimeout(
																				( ) =>
																				{
																					this.getSLData();
																				}

																				, 250
																	)
																}
															}
												>
													<input type="radio" name="options" id="option1" checked autoComplete="off"/>

													<Trans>installation.24h</Trans>

												</label>



												<label
													className = "btn btn-sm btn-light "
													id = "option5dLabel"

													onClick =
															{
																( e ) =>
																{
																	setTimeout(
																				( ) =>
																				{
																					this.getSLData();
																				}
																				,
																				250
																	)
																}
															}
												>
													<input type="radio" name="options" id="option2" autoComplete="off" />

													<Trans>installation.5d</Trans>

												</label>


											</div>

											{
											false  &&

											<div>
												<img src="img/ico/gray/ico-c-download.svg" data-toggle="tooltip" data-placement="bottom" title="" alt="" data-original-title=""/>
												<img src="img/ico/gray/ico-c-image.svg" data-toggle="tooltip" data-placement="bottom" title="" alt="" data-original-title=""/>
											</div>
											}

										</div>

									</div>



								{
									// GRÁFICA PARA STREETLIGHTS CON CPU >= 2

									(
										(  Math.abs( parseInt(this.state.selected_streetlight.cpu.version) )  ==  vCPU[ "klm27v2" ]  )	// 2
										||
										(  Math.abs( parseInt(this.state.selected_streetlight.cpu.version) )  ==  vCPU[ "elorav1" ]  )	// 3
									)
									&&

									<div>

										{

										( this.state.selected_streetlight.name  !==  "" )  &&


										<div style={{marginBottom: "150px", width: "100%"}}>





											{

												(  this.props.auth.user.user_profile.userlevel  ==  "EKIONA"  )  &&

												<center>

													<table style={{marginBottom: "-3rem"}}>

														<tr style={{width: "100%"}}>

															<CLOUDS_number_Forecast_on_Graphs/>

														</tr>

													</table>


												</center>
											}



											{
											(  this.state.selected_streetlight.hybrid == true  ) &&

											<BasicChart

												data = { this.state.RESULTS }

												vars =	{
															[
																this.props.t( "installation.consumption" ),
																this.props.t( "installation.charging" ),
																this.props.t( "installation.generation" ),

																this.props.t( "installation.myRS" )
															]
														}

												showSpinner = {this.state.showSpinner}

												chartType = {"lines"}
												height={400}
												maxy={100}

												yunit={" %"}
											/>
											}




											{
											(																												// _NO_ ES HÍBRIDA
												//(  this.props.auth.user.username  ==  "urretxu_admin"  )  ||				// SERGIO 07-03-23:  EL USER "URRETXU_ADMIN" TAMBIÉN PODRÁ VER ÉSTA GRÁFICA (EN PRINCIPIO, ESTO ES TEMPORAL)
												(  this.props.auth.user.user_profile.userlevel  ==  "EKIONA"  )
											)  &&

											(  this.state.selected_streetlight.hybrid == true  ) &&

											<BasicChart

												data = { this.state.RESULTS }

												vars =	{
															[
																this.props.t( "installation.consumption_a" ),
																this.props.t( "installation.battery_v" ),
																this.props.t( "installation.panel_v" ),

																this.props.t( "installation.myRS" )
															]
														}

												showSpinner = {this.state.showSpinner}

												chartType = {"lines"}
												height={400}


												maxy={  MAX_Nominal_Voltage  }

												//yunit={" V"}
											/>
											}





											{ /*----*/ }







											{
											(  this.state.selected_streetlight.hybrid  ==  false  ) &&

											<BasicChart

												data = { this.state.RESULTS }

												vars =	{
															[
																this.props.t( "installation.consumption" ),
																this.props.t( "installation.charging" ),
																this.props.t( "installation.generation" ),

																(  this.props.auth.user.user_profile.userlevel  ==  "EKIONA"  )		?	"FC"	:		""
															]
														}

												showSpinner = {this.state.showSpinner}

												chartType = {"lines"}


												height={400}

												//width= { 800 }

												maxy={ 100 }

												yunit={" %"}
											/>
											}




											{

											(
												(  this.props.auth.user.username  ==  "urretxu_admin"  )  ||						// SERGIO 07-03-23:  EL USER "URRETXU_ADMIN" TAMBIÉN PODRÁ VER ÉSTA GRÁFICA (EN PRINCIPIO, ESTO ES TEMPORAL)
												(  this.props.auth.user.user_profile.userlevel  ==  "EKIONA"  )
											) &&

											(  this.state.selected_streetlight.hybrid == false  ) &&

											<BasicChart

												data = { this.state.RESULTS }

												vars =	{
															[
																this.props.t( "installation.consumption_a" ),
																this.props.t( "installation.battery_v" ),
																this.props.t( "installation.panel_v" ),

																//(  this.props.auth.user.user_profile.userlevel  ==  "EKIONA"  )		?	"FC"	:		""
															]
														}

												showSpinner = {this.state.showSpinner}

												chartType = {"lines"}
												height={400}

												maxy={  MAX_Nominal_Voltage  }


												//yunit={" V"}
											/>

											}






											{/*---------------------------------------*/}






											{
											this.state.selected_streetlight.has_presence && 		// DETECTOR DE PRESENCIA


											<BasicChart

												data={this.state.RESULTS}
												vars=
													{
														[ this.props.t("installation.counting") ]
													}
												showSpinner={this.state.showSpinner}

												height={350}
												chartType={"bars"}
												maxy={4}

												yunit={""}

											/>
											}




											{
												//MGH BGN
											}
											{
											this.state.selected_streetlight.has_air_sensor &&

											(  this.state.selected_streetlight.hybrid == false  ) &&

											<BasicChart
												data = { this.state.RESULTS }
												vars =
														{
															[
																"SO2",
																"CO",
																"NO2",

																"O3"
															]
														}
												showSpinner={this.state.showSpinner}
												chartType={"lines"}
												height={400}
												maxy={400}

												yunit={" ppb "}
											/>
											}

											{
												// MGH END
											}

											{
												// MGH BGN
											}

											{
											this.state.selected_streetlight.has_air_sensor &&

											<BasicChart

												data={ this.state.RESULTS }
												vars=
														{
															[
																"PM1.0",
																"PM2.5",
																"PM10"
															]
														}
												showSpinner={ this.state.showSpinner }
												chartType={"lines"}
												height={400}
												maxy={100}
												yunit={" ug/m³"}
											/>
											}

											{
												// MGH END
											}

										</div>
										}

									</div>

								}




								{
									// SI LA STREETLIGHT CPU ES LA 1, Y SI SE PINTA ÉSTA GRÁFICA ADICIONNAL DE CONTEO

									(  Math.abs( parseInt( this.state.selected_streetlight.cpu.version ) )  ==  vCPU[ "klm27v1" ]  )  &&  // 1

									<div>


									{

										(  this.props.auth.user.user_profile.userlevel  ==  "EKIONA"  )  &&

										<center>

											<table style={{marginBottom: "-3rem"}}>

												<tr style={{width: "100%"}}>

													<CLOUDS_number_Forecast_on_Graphs/>

												</tr>
											</table>


										</center>
									}


									{
										this.state.selected_streetlight.name  !==  ""  &&

										<div style={{marginBottom: "150px"}}>

											{
											this.state.selected_streetlight.hybrid  ==  false &&

											<BasicChart

												data = { this.state.RESULTS }

												vars =
														{
															[
																this.props.t( "installation.consumption_a" ),
																this.props.t( "installation.battery_v" ),
																this.props.t( "installation.panel_v" ),
															]
														}


												showSpinner = {this.state.showSpinner}

												chartType = {"lines"}

												height = {400}

												maxy={  MAX_Nominal_Voltage   }

												//maxy = {24}

												//yunit={ "" }
											/>
											}

											{
											this.state.selected_streetlight.hybrid  ==  true &&

											<BasicChart

												data = { this.state.RESULTS }

												vars =
														{
															[
																this.props.t( "installation.consumption_a" ),
																this.props.t( "installation.battery_v" ),
																this.props.t( "installation.panel_v" ),
																this.props.t( "installation.myRS" )
															]
														}


												showSpinner = {this.state.showSpinner}

												chartType = {"lines"}

												height = {400}

												maxy={  MAX_Nominal_Voltage   }

												//maxy = {24}

												//yunit={ "" }
											/>

											}

											{

											this.state.selected_streetlight.has_presence  && 		// DETECTOR DE PRESENCIA   (Y ARRIBA, TAMBIÉN, CPU 1)

											<BasicChart

													data = { this.state.RESULTS }
													vars =
															{
																[ this.props.t("installation.counting"), ]
															}
													showSpinner = { this.state.showSpinner }

													height = {350}

													chartType = {"bars"}
													maxy = {4}

													yunit = {""}
											/>

											}



										</div>
									}
									</div>
								}

								</div>








								<div id="weatherInfo" className="modal fade" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">

									<div className="modal-dialog modal-lg modal-dialog-centered">
										<div className="modal-content">

											<div className="modal-header">
												<h5 className="modal-title"></h5>

												<button type="button" className="close" data-dismiss="modal" aria-label="Close">
													<span aria-hidden="true">×</span>
												</button>
											</div>


											<div className="modal-body" id="infoModal">

												<h4>{this.props.t("installation.cloud_forecast")}</h4>

												<table className="table-sm table-striped" style={{width: "100%"}}>
												<tbody>
												{
													Object.keys( weatherDays ).length > 2  &&

													Object.keys( weatherDays ).slice( 0, 4 ).map(
													//

														( day, dayindex ) =>
														{
															return(

																	<tr st>

																		<th scope="row">
																			{weatherDays[day].day}
																		</th>

																		<td>
																			<LineChart width={600} style={{marginLeft:"auto"}} height={85} data={weatherDays[day].values}>

																				<YAxis
																					ticks = { [-1,0, 100] }
																					tickFormatter=
																									{
																										( label ) =>
																										{
																											if (  label  ==  100  )
																											{
																												return this.props.t( "installation.cloudy" )
																											}
																											else
																											{
																												return this.props.t( "installation.sunny" )
																											}
																										}
																									}
																				/>

																				<Line type="monotone" dataKey="clouds" stroke="#8884d8" strokeWidth={2}/>

																				<Tooltip content={<CustomTooltip me={this}/>}/>

																			</LineChart>
																		</td>

																	</tr>
															) // return
														}
													//
													)
												}
												</tbody>
												</table>

											</div>

											<div className="modal-footer">

												{
												this.props.auth.user.user_profile.userlevel == "EKIONA" &&

												<div>
													<center> <h4>Forecast:  <b>{  our_Forecast_Avg_Sent  }</b></h4> </center>

													<center> <h4>Atenuación:  <b>{  our_FC_coef  }</b></h4> </center>
												</div>
												}


													{

													//console.log(  this.state.selected_streetlight.installation_id  )  &&
													//console.log(  this.state.selected_installationgroup.installations  )  &&

													//console.log( this.state.selected_streetlight )

													/*
													this.state =
																		installationgroups: [],

																		selected_installationgroup:
																									{
																										group_name: "",

																										id: "",

																										info:
																											{
																												streetlights: [],

																												available_sensors:[]
																											}
																									},

																		selected_streetlight:
																							{
																								name: "",
																								cpu: { version: 0 },
																								battery: { cycle_count: 0 },

																								hybrid: "",

																								combined_sensors: []
																							},

													*/
													}

												<button type="button" data-dismiss="modal" className="btn btn-orange">
													Ok
												</button>

											</div>

										</div>
									</div>

								</div>






								<div id="webcamInfo" className="modal fade" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">

									<div className="modal-dialog modal-lg modal-dialog-centered">
										<div className="modal-content">

											<div className="modal-header">

												<h5 className="modal-title">
												</h5>

												<button type="button" className="close" data-dismiss="modal" aria-label="Close">
													<span aria-hidden="true">
														×
													</span>
												</button>

											</div>

											<div className="modal-body" id="infoModal">
												<img id="webcamIframe" style={{width: "100%", height: "350px"}} src=""/>
											</div>

											<div className="modal-footer">
												<button type="button" data-dismiss="modal" className="btn btn-orange">
													Ok
												</button>
											</div>

										</div>
									</div>

								</div>

								{ /*------------------------------------------*/ }

							</main>

						</div>


					</div>


		) // return ( ... )

	} // render ()


} // class Instalaciones extends Component


//---


const mapStateToProps =
							function ( state )
							{
								return  {	auth: state.auth,
											core: state.core
										}
							}


const mapDispatchToProps =
							{
								getInstallations: getInstallations,
								getInstallationDetail: getInstallationDetail
							}


export default GoogleApiWrapper(  {   apiKey: CONFIG.google_maps_api_key  }  )  (  connect( mapStateToProps, mapDispatchToProps )  ( withTranslation() (Instalaciones) )  );